import React, { Component } from "react";
import PropTypes from "prop-types";
import { Layout, Menu, Tag } from "antd";
import { NavLink } from "react-router-dom";

import { Footer, IconLight } from "../../_components";

const SUBSCRIPTION_STATUS = {
  SUBSCRIBED: "SUBSCRIBED",
  BLOCKED: "BLOCKED",
  NOT_SUBSCRIBED: "NOT_SUBSCRIBED",
  TRIAL: "TRIAL"
};

class SiderApp extends Component {
  state = {
    location: this.props.location,
    summaryHasAccess: this.props.summaryHasAccess,
    accountEmail: this.props.accountEmail,
    isCollapsed: true,
    appsSubscription: this.props.appsSubscription,
    subscriptionStatus: this.props.subscriptionStatus
  };

  onCollapseToggle = collapsed => {
    this.setState({
      isCollapsed: collapsed
    });
  };
  iconStyle = {
    marginRight: "8px"
    // color: "rgb(13, 117, 110)"
  };

  componentWillReceiveProps = newProps => {
    if (this.state.location !== newProps.location) {
      this.setState({ location: newProps.location });
    }
  };
  static getColorByStatus(status) {
    switch (status) {
      case SUBSCRIPTION_STATUS.SUBSCRIBED:
        return "green";
      case SUBSCRIPTION_STATUS.NOT_SUBSCRIBED:
        return "gray";
      case SUBSCRIPTION_STATUS.TRIAL:
        return "blue";
      case SUBSCRIPTION_STATUS.BLOCKED:
        return "red";
      default:
        return "black";
    }
  }
  render() {
    const {
      location,
      accountEmail,
      isCollapsed,
      appsSubscription,
      subscriptionStatus
    } = this.state;
    const { isDarkTheme } = this.props;
    const menuSelectedKeys = [location.pathname];

    return (
      <Layout.Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={this.onCollapseToggle}
        width={280}
        style={{
          backgroundColor: isDarkTheme ? "rgb(56,56,56)" : "rgb(20, 54, 96)"
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={"/images/pdView-icon-small.png"}
            alt={"WAEG logo"}
            style={{ maxWidth: "54px" }}
          />
          <span>
            <h3
              style={{ color: "white", lineHeight: "18px", fontWeight: "bold" }}
            >
              WAEG
            </h3>
          </span>
          {subscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBED && (
            <Tag
              style={{ marginLeft: "8px" }}
              color={SiderApp.getColorByStatus(subscriptionStatus)}
            >
              {subscriptionStatus}
            </Tag>
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={menuSelectedKeys}
          style={{
            backgroundColor: isDarkTheme ? "rgb(56,56,56)" : "rgb(20, 54, 96)"
          }}
        >
          {/*{summaryHasAccess && (*/}
          {/*<Menu.Item key="/app/summary">*/}
          {/*<NavLink to="/app/summary">*/}
          {/*<IconLight*/}
          {/*icon={"table"}*/}
          {/*style={this.iconStyle}*/}
          {/*className={"anticon"}*/}
          {/*/>*/}
          {/*<span>Summary</span>*/}
          {/*</NavLink>*/}
          {/*</Menu.Item>*/}
          {/*)}*/}

          <Menu.SubMenu
            title={
              <span>
                <IconLight
                  icon={"info-circle"}
                  className={"anticon"}
                  style={this.iconStyle}
                />
                <span>Help</span>
              </span>
            }
          >
            {/*<Menu.Item key="help.tour">*/}
            {/*<a>*/}
            {/*<IconLight icon={"rocket"} style={this.iconStyle} />*/}
            {/*Start tour*/}
            {/*</a>*/}
            {/*</Menu.Item>*/}
            <Menu.Item key="help.docs">
              <a
                href="https://pdview.atlassian.net/wiki/spaces/DAEGKB/pages/516096004/WAEG+exceptions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconLight icon={"info-circle"} style={this.iconStyle} />
                Help docs
              </a>
            </Menu.Item>
            {/*<Menu.Item key="help.videos">*/}
            {/*<a*/}
            {/*// href="https://www.youtube.com/watch?v=_EcGghRXYKM&list=PLuss093rSJjM-Aww9Jylw4AwHc5ePex5x"*/}
            {/*target="_blank"*/}
            {/*rel="noopener noreferrer"*/}
            {/*>*/}
            {/*<IconLight icon={"video"} style={this.iconStyle} />*/}
            {/*Help videos*/}
            {/*</a>*/}
            {/*</Menu.Item>*/}
            <Menu.Item key="help.contact">
              <a
                href="http://pdview.com.au/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconLight icon={"comment"} style={this.iconStyle} />
                Contact us
              </a>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            title={
              <span>
                <IconLight
                  icon={"grip-horizontal"}
                  className={"anticon"}
                  style={this.iconStyle}
                />
                <span>Apps</span>
              </span>
            }
          >
            {appsSubscription &&
              Object.keys(appsSubscription) &&
              Object.keys(appsSubscription).map(i => [
                <Menu.Divider />,
                <Menu.ItemGroup key={i} title={<b>{i}</b>}>
                  {appsSubscription[i] &&
                    appsSubscription[i].map(a => (
                      <Menu.Item key={a.app_name}>
                        <a
                          href={a.app_url}
                          target={"_blank"}
                          title={a.subscription_status}
                        >
                          {a.app_name}
                          <Tag
                            style={{ marginLeft: "8px" }}
                            color={SiderApp.getColorByStatus(
                              a.subscription_status
                            )}
                          >
                            {a.subscription_status}
                          </Tag>
                        </a>
                      </Menu.Item>
                    ))}
                </Menu.ItemGroup>
              ])}
          </Menu.SubMenu>

          <Menu.SubMenu
            title={
              <span>
                <IconLight
                  icon={"user-circle"}
                  className={"anticon"}
                  style={this.iconStyle}
                />
                <span>Account</span>
              </span>
            }
          >
            <Menu.ItemGroup title={accountEmail}>
              <Menu.Item key="accountinfo">
                <a href={"https://apps.pdview.com.au/"}>
                  <IconLight icon={"user"} style={this.iconStyle} />
                  Account info
                </a>
              </Menu.Item>

              <Menu.Item key="logout">
                <NavLink to="/logout">
                  <IconLight icon={"sign-out"} style={this.iconStyle} />
                  Log out
                </NavLink>
              </Menu.Item>
            </Menu.ItemGroup>

            {/*<Menu.Item key="help.about">*/}
            {/*<a*/}
            {/*href="http://pdview.com.au/"*/}
            {/*target="_blank"*/}
            {/*rel="noopener noreferrer"*/}
            {/*>*/}
            {/*<FontAwesomeIcon icon={["fal", "info-square"]} /> About us*/}
            {/*</a>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key="help.terms">*/}
            {/*<a*/}
            {/*href="http://pdview.com.au/terms"*/}
            {/*target="_blank"*/}
            {/*rel="noopener noreferrer"*/}
            {/*>*/}
            {/*<FontAwesomeIcon icon={["fal", "file-contract"]} /> Terms of use*/}
            {/*</a>*/}
            {/*</Menu.Item>*/}

            {/*<Menu.Divider />*/}
            {/*<Menu.ItemGroup*/}
            {/*title={*/}
            {/*<AccountInfo*/}
            {/*email={accountEmail}*/}
            {/*status={accountStatus}*/}
            {/*daysLeft={accountDaysLeft}*/}
            {/*/>*/}
            {/*}*/}
            {/*/>*/}
          </Menu.SubMenu>
        </Menu>{" "}
        {!isCollapsed && (
          <Footer
            style={{
              color: "rgb(133, 145, 160)",
              padding: "8px",
              borderTop: "1px solid rgb(133, 145, 160)"
            }}
          />
        )}
      </Layout.Sider>
    );
  }
}

SiderApp.propTypes = {
  location: PropTypes.object.isRequired,
  summaryHasAccess: PropTypes.bool.isRequired,
  accountEmail: PropTypes.string.isRequired,
  accountStatus: PropTypes.string.isRequired,
  accountDaysLeft: PropTypes.number.isRequired,
  appsSubscription: PropTypes.object.isRequired,
  subscriptionStatus: PropTypes.string.isRequired,
  isDarkTheme: PropTypes.bool.isRequired
};

export default SiderApp;
