import React from "react";
import PropTypes from "prop-types";

import { ReportViewTypes } from "./ReportViewTypes";
import { ButtonGroupSingleSelection } from "../button_group_single_selection";

const ReportViewOptions = [
  { text: "Table", value: ReportViewTypes.Table, icon: "table" },
  { text: "Chart", value: ReportViewTypes.Chart, icon: "chart-bar" }
];

const ReportViewPicker = ({ selectedValue, onChange, style }) => {
  return (
    <div style={style}>
      <ButtonGroupSingleSelection
        options={ReportViewOptions}
        selectedValue={selectedValue}
        onChange={onChange}
      />
    </div>
  );
};

ReportViewPicker.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object
};

export default ReportViewPicker;
