import React from "react";
import { Alert } from "antd";
import PropTypes from "prop-types";

const PageAlert = ({ message, description, style }) => {
  return (
    <div style={{ margin: "16px auto", maxWidth: "300px", ...style }}>
      <Alert
        showIcon
        type={"warning"}
        message={message}
        description={description}
      />
    </div>
  );
};

PageAlert.propTypes = {
  style: PropTypes.object,
  message: PropTypes.string,
  description: PropTypes.string
};

export default PageAlert;
