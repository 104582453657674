import React from "react";
import PropTypes from "prop-types";
import commaNumber from "comma-number";

const CellPriceValue = ({ style, value }) => {
  return (
    <span style={style}>
      {value && <span>{commaNumber(value.toFixed(2))}</span>}
    </span>
  );
};

CellPriceValue.propTypes = {
  style: PropTypes.object,
  value: PropTypes.number
};

export default CellPriceValue;
