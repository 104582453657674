import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconLight = ({ icon, title, style, className }) => {
  return (
    <span className={className} style={style} title={title}>
      <FontAwesomeIcon icon={["fal", icon]} />
    </span>
  );
};

IconLight.propTypes = {
  style: PropTypes.object,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string
};

export default IconLight;
