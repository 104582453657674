import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table } from "antd";

import {
  ReportVisibilityPicker,
  ReportSortPicker,
  // ReportViewPicker,
  ReportVisibilityTypes,
  TableGroupActions,
  ReportViewTypes,
  InfoTooltip,
  ThemeTypes,
} from "../../_components";

import { TableRowTypes } from "./TableRowTypes";
import {
  CellGenGroupName,
  CellRegionName,
  CellSummaryValue,
  CellVisibilityGroup,
  CellShowAll,
  CellPriceValue,
  CellRegionRowName,
  CellSparkline,
} from "./cell";
import "./reportTable.scss";
import { DataFormatting } from "../../../utils/helpers";

const IS_DEBUG = false;

const ReportTable = ({
  isLoading,
  selectedReportVisibility,
  onChangeReportVisibility,
  selectedSort,
  onChangeSort,
  selectedReportView,
  onChangeReportView,
  showSummary,
  rows,
  tableHeight,
  selectedReportDays,
  style,
  onGroupAction,
  startDate,
  selectedChart,
  selectedTheme,
  showSparklines,
  showNow,
}) => {
  const isDarkTheme = selectedTheme === ThemeTypes.Dark;
  const linkColor = isDarkTheme ? "rgb(33, 208, 202)" : "rgb(8, 130, 126)";

  const sparklineWidth = showSummary ? 320 : window.innerWidth - 750;

  const data = rows;
  if (IS_DEBUG) console.log("TABLE DATA", data);

  let dateTimeSeries = [];
  const priceRows = rows.filter((r) => r.rowType === TableRowTypes.RegionPrice);
  if (priceRows && priceRows.length > 0) {
    const priceRow = priceRows[0]; //any region price will do, just need to calculate date time series
    if (priceRow && priceRow.forecast) {
      dateTimeSeries = DataFormatting.getDateTimeSeries(
        priceRow.forecast,
        "settlementdate"
      );
    }
  }

  const { midnightPlots, weekendBands } = DataFormatting.getChartBands(
    dateTimeSeries,
    isDarkTheme ? "#999" : "#aaa",
    isDarkTheme ? "#454545" : "#eee"
  );

  const onGroupSelect = (regionId, groupId) => () => {
    if (selectedReportView !== ReportViewTypes.Chart)
      onChangeReportView(ReportViewTypes.Chart); //if chart view is not selected, switch to it
    onGroupAction(TableGroupActions.Select, regionId, groupId); //select group for chart
  };

  const getCol1 = (record) => {
    switch (record.rowType) {
      case TableRowTypes.Region:
        return (
          <CellShowAll
            regionId={record.region_id}
            onClick={onGroupAction}
            linkColor={linkColor}
          />
        );
      case TableRowTypes.GenGroup:
        return (
          <CellVisibilityGroup
            selectedReportVisibility={selectedReportVisibility}
            onClick={onGroupAction}
            groupId={record.group_id}
            regionId={record.region_id}
            linkColor={linkColor}
          />
        );
      case TableRowTypes.Portfolio:
      case TableRowTypes.Fuel:
      case TableRowTypes.RegionPrice:
        return null;
      default:
        return <div>TODO</div>;
    }
  };

  const getCol2 = (record) => {
    switch (record.rowType) {
      case TableRowTypes.Region:
        return (
          <CellRegionName
            regionName={record.region_id}
            shownGroupsCount={
              selectedReportVisibility === ReportVisibilityTypes.Visible
                ? record.groupCount.visible
                : record.groupCount.hidden
            }
            totalGroupsCount={record.groupCount.total}
          />
        );

      case TableRowTypes.GenGroup:
        return (
          <CellGenGroupName
            groupName={record.group_name}
            portfolio={record.portfolio}
            capacity={record.capacity}
            fuelType={record.fuel}
            equation={record.equation}
            onClick={onGroupSelect(record.region_id, record.group_id)}
            linkColor={linkColor}
          />
        );
      case TableRowTypes.Portfolio:
        return <span style={{ fontWeight: "bold" }}>{record.portfolio}</span>;
      case TableRowTypes.Fuel:
        return <span style={{ fontWeight: "bold" }}>{record.fuel}</span>;
      case TableRowTypes.RegionPrice:
        return (
          <CellRegionRowName
            name={"Price"}
            tooltip={
              "Now is latest dispatchprice. All Day prices are the maximum price for the day (from 0:30 to 0:00)."
            }
          />
        );
      default:
        return <div>TODO</div>;
    }
  };

  const getNowCol = (record) => {
    switch (record.rowType) {
      case TableRowTypes.Region:
        return null;
      case TableRowTypes.RegionPrice:
        return <CellPriceValue value={record["price"]} />;
      case TableRowTypes.GenGroup:
        return (
          <CellSummaryValue value={record["now"] && record["now"]["mw"]} />
        );
      case TableRowTypes.Portfolio:
      case TableRowTypes.Fuel:
        return (
          <CellSummaryValue
            style={{ fontWeight: "bold" }}
            value={record["now"] && record["now"]["mw"]}
          />
        );

      default:
        return <span>TODO</span>;
    }
  };

  const getSparklineCol = (record) => {
    switch (record.rowType) {
      case TableRowTypes.Region:
      case TableRowTypes.Portfolio:
      case TableRowTypes.Fuel:
        return null;
      case TableRowTypes.GenGroup:
        return (
          <CellSparkline
            isDarkTheme={isDarkTheme}
            data={record["forecast"]}
            dtKey={"settlementdate"}
            valueKey={"expected_generation"}
            seriesName={"Generation"}
            midnightPlots={midnightPlots}
            weekendBands={weekendBands}
            dateTimeSeries={dateTimeSeries}
            width={sparklineWidth}
          />
        );
      case TableRowTypes.RegionPrice:
        return (
          <CellSparkline
            isDarkTheme={isDarkTheme}
            data={record["forecast"]}
            dtKey={"settlementdate"}
            valueKey={"price"}
            seriesName={"Price"}
            midnightPlots={midnightPlots}
            weekendBands={weekendBands}
            dateTimeSeries={dateTimeSeries}
            width={sparklineWidth}
          />
        );
      default:
        return <span>TODO</span>;
    }
  };

  const getSummaryCol = (record, dayIndex) => {
    switch (record.rowType) {
      case TableRowTypes.GenGroup:
      case TableRowTypes.Region:
      case TableRowTypes.Portfolio:
      case TableRowTypes.Fuel:
        if (
          record &&
          record[`day-${dayIndex}`] &&
          record[`day-${dayIndex}`].mw !== undefined
        )
          return (
            <CellSummaryValue
              value={record[`day-${dayIndex}`].mw}
              style={{
                fontWeight:
                  record.rowType === TableRowTypes.GenGroup ? "normal" : "bold",
              }}
            />
          );
        else return null;
      case TableRowTypes.RegionPrice:
        if (
          record &&
          record[`day-${dayIndex}`] &&
          record[`day-${dayIndex}`].max_price
        )
          return <CellPriceValue value={record[`day-${dayIndex}`].max_price} />;
        else return null;
      default:
        return null;
    }
  };

  //get day of week based on day index and start date
  const getDayOfWeek = (dayNumber, startDate) => {
    const m_startDate = moment(startDate).utcOffset("+1000");

    if (m_startDate) {
      const dt = m_startDate.add(dayNumber, "days");
      return dt.format("ddd");
    }

    return "---";
  };

  return (
    <div style={style} className={`theme-${selectedTheme}`}>
      <Table
        loading={isLoading}
        size={"middle"}
        dataSource={data}
        className={"report-table"}
        //columns={columns}
        bordered={true}
        useFixedHeader={true}
        scroll={{ x: true, y: tableHeight }}
        pagination={false}
        rowClassName={(record, index) =>
          `row-type-${record.rowType} ${
            selectedReportView === ReportViewTypes.Chart &&
            record.group_id === selectedChart.groupId
              ? "row-selected"
              : ""
          }`
        }
      >
        <Table.Column
          title={
            <ReportVisibilityPicker
              onChange={onChangeReportVisibility}
              selectedValue={selectedReportVisibility}
            />
          }
          width={100}
          key={"col1"}
          align={"center"}
          render={(record) => getCol1(record)}
          //fixed={true}
        />
        <Table.Column
          title={
            <div>
              <b>
                {selectedReportVisibility === ReportVisibilityTypes.Visible
                  ? "Visible rows"
                  : "Hidden rows"}
              </b>

              <ReportSortPicker
                style={{ display: "inline-block", marginLeft: "8px" }}
                selectedValue={selectedSort}
                onChange={onChangeSort}
              />
              {/*<ReportViewPicker*/}
              {/*style={{ display: "inline-block", marginLeft: "8px" }}*/}
              {/*onChange={onChangeReportView}*/}
              {/*selectedValue={selectedReportView}*/}
              {/*/>*/}
            </div>
          }
          width={400}
          key={"text"}
          render={(record) => getCol2(record)}
          // fixed={true}
        />
        {showNow && (
          <Table.Column
            title={
              <span>
                <InfoTooltip content={"Latest dispatch value"} /> <b>Now</b>
              </span>
            }
            key={"now"}
            render={(record) => getNowCol(record)}
            align={"center"}
          />
        )}

        {showSummary &&
          selectedReportDays.map((d, i) => (
            <Table.Column
              title={
                <span>
                  {i === 0 && (
                    <InfoTooltip
                      content={
                        "Table values are the average generation during the maximum priced period(s) of the day."
                      }
                      style={{ float: "left" }}
                    />
                  )}{" "}
                  <b style={{ whiteSpace: "nowrap" }}>DAY {d}</b> <br />{" "}
                  {getDayOfWeek(d, startDate)}
                </span>
              }
              key={`summary-day-${d}`}
              render={(record) => getSummaryCol(record, d)}
              align={"center"}
              //width={150}
            />
          ))}
        {showSparklines && (
          <Table.Column
            title={
              <span>
                <div style={{ float: "left", width: 180, textAlign: "center" }}>
                  <InfoTooltip
                    content={
                      "Minimum and maximum values for the selected days in the sparkline. Average is the mean of all values."
                    }
                  />{" "}
                  <b>Min : Avg : Max</b>
                </div>
                <InfoTooltip
                  content={"Grey for maximum magnitude value less than 10"}
                />{" "}
                <b>Sparkline</b>
              </span>
            }
            key={"sparkline"}
            render={(record) => getSparklineCol(record)}
            align={"center"}
            width={sparklineWidth + 20}
            style={{ textAlign: "center" }}
          />
        )}
      </Table>
    </div>
  );
};

ReportTable.propTypes = {
  style: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onChangeReportVisibility: PropTypes.func.isRequired,
  selectedReportVisibility: PropTypes.string.isRequired,
  selectedSort: PropTypes.string.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  selectedReportView: PropTypes.string.isRequired,
  onChangeReportView: PropTypes.func.isRequired,
  showSummary: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  tableHeight: PropTypes.number.isRequired,
  selectedReportDays: PropTypes.array.isRequired,
  onGroupAction: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  selectedChart: PropTypes.object,
  selectedTheme: PropTypes.string.isRequired,
  showSparkline: PropTypes.bool.isRequired,
  showNow: PropTypes.bool.isRequired,
};

export default ReportTable;
