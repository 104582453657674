
import { useState, useEffect, React} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { blue as palette } from '@ant-design/colors';

import { InfoTooltip, ThemeTypes } from "../../_components";

const fullDtFormat = "ddd DD-MM-YYYY HH:mm";
const TimerCountdown = ({
  datetime,
  datetimestr,
  label,
  format,
  tooltip,
  style,
  selectedTheme,
  expire
}) => {
  const dtFormat = format ? format : fullDtFormat;
  const staleColour = { 
    colour: "#999", 
    bgColour: selectedTheme === ThemeTypes.Dark ? "#444" : "#fff",
    timeColour: selectedTheme === ThemeTypes.Dark ? "#ddd" : "#222",
  };
  const [colourScheme, setColourScheme] = useState(staleColour);

  useEffect(() => {
    let timer = setInterval(() => {
      updateProgress();
    }, 1000);
    return () => clearInterval(timer)
  });
  let dtStr = datetimestr;
  if (!datetimestr)
    dtStr = datetime
      ? moment(datetime)
          .utcOffset("+1000")
          .format(dtFormat)
      : "...";
  const dtFullStr = datetime
    ? moment(datetime)
        .utcOffset("+1000")
        .format(fullDtFormat)
    : "";

 const  updateProgress = () => {
    const minutes = moment().diff(moment(datetime), 'minutes');
    //const minutes = 11; // for testing only - REMOVE 
    if (minutes >= expire || minutes < 0) {
      setColourScheme(staleColour);
    }
    else {
      const freshness = minutes === 0 ? 0 : Math.floor((expire - minutes) / expire * 10); // number between 0 and 9, used to determine highlight
      const colour = {
        bgColour: palette[freshness],
        colour: (freshness > 5 ? "rgb(255, 255, 255)" : "rgba(0, 0, 0, 0.85)"),
        timeColour:  (freshness > 5 ? "rgb(255, 255, 255)" : "rgba(0, 0, 0, 1)"),
      };
      setColourScheme(colour);
    }
    
  };

  return (
    <div
      style={{
        display: "inline-block",
        backgroundColor: colourScheme.bgColour,
        padding: "8px 16px",
        borderRadius: "4px",
        margin: "0 8px",
        ...style
      }}
    >
      {tooltip && <InfoTooltip content={tooltip} placement={"bottom"} />}
      <label style={{ margin: "0 8px", color: colourScheme.colour }}>{label}</label>
      <span
        style={{ fontWeight: "bold", color: colourScheme.timeColour }}
        title={dtFullStr}
      >
        {dtStr}
      </span>
    </div>
  );
};

TimerCountdown.propTypes = {
  style: PropTypes.object,
  datetime: PropTypes.string,
  expire: PropTypes.number,
  datetimestr: PropTypes.string,
  label: PropTypes.string.isRequired,
  format: PropTypes.string,
  tooltip: PropTypes.string,
  selectedTheme: PropTypes.string.isRequired
};

export default TimerCountdown;
