import React, { Component } from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import _ from "lodash";
import {
  ButtonBorderless,
  IconLight,
  ReportViewTypes,
  ThemeTypes
} from "../../_components";

const MillisecondsOffset = 10 * 60 * 60 * 1000; //utc + 10 hours
const FIFTEEN_MINUTES = 1000 * 60 * 15;
const ONE_DAY = 1000 * 60 * 60 * 24;

class ReportChart extends Component {
  static propTypes = {
    style: PropTypes.object,
    data: PropTypes.object,
    height: PropTypes.number,
    onChangeReportView: PropTypes.func.isRequired,
    selectedTheme: PropTypes.string.isRequired
  };

  static formatDateTime(dtRaw) {
    return new Date(dtRaw).getTime() + MillisecondsOffset; //return datetime as required by highcharts
  }

  static formatPriceValue(priceRaw) {
    if (priceRaw !== undefined) {
      return parseFloat(priceRaw.toFixed(2));
    }
    return priceRaw;
  }

  static formatGenValue(genRaw) {
    if (genRaw !== undefined) {
      return Math.floor(genRaw);
    }
    return genRaw;
  }

  getSeriesData = () => {
    const { data, selectedTheme } = this.props;
    const isDarkTheme = selectedTheme === ThemeTypes.Dark;
    let genSeries = [];
    let priceSeries = [];
    let midnightPlots = [];
    let genNullBands = [];
    let weekendBands = [];
    let dts = [];
    //process price series
    if (data && data.regionData && data.regionData.data) {
      //price series
      const priceData = data.regionData.data;
      priceData.forEach(d => {
        const dtRaw = d["settlementdate"];
        const priceRaw = d["price"];
        const dt = ReportChart.formatDateTime(dtRaw);
        const value = ReportChart.formatPriceValue(priceRaw);
        dts.push(dt); //add to datetime series array, for null checks in gen data
        priceSeries.push([dt, value]);
        //add midnight plots
        const mdt = moment(dtRaw).utcOffset("+1000");
        if (mdt.hours() === 0 && mdt.minutes() === 0) {
          /* Offset by 15 minutes (12:15 am)*/
          const time = dt + FIFTEEN_MINUTES;
          midnightPlots.push({
            value: time,
            zIndex: 2,
            width: 1,
            dashStyle: "dash",
            color: isDarkTheme ? "#222" : "#ccc",
            label: {
              text: mdt.format("ddd"),
              style: { color: isDarkTheme ? "#888" : "#ccc" }
            }
          });
          const day = mdt.day();
          if (day === 0 || day === 6) {
            //weekend plots, only checking on midnight
            weekendBands.push({
              id: `weekend-${weekendBands.length}`,
              color: isDarkTheme ? "#454545" : "#eee",
              from: dt,
              to: dt + ONE_DAY
            });
          }
        }
      });
    }

    dts = _.uniq(dts);

    if (data && data.groupData && data.groupData.data) {
      //gen series and gen null checks
      const genData = data.groupData.data;
      let startMarker = 0;
      let endMarker = 0;
      let inNullZone = false;
      for (let i = 0, j = 0; i < dts.length; i++) {
        const dt = dts[i];
        const gen = genData[j];
        let match = false;
        if (gen) {
          const dtRaw = gen["settlementdate"];
          const genRaw = gen["expected_generation"];
          const genDt = ReportChart.formatDateTime(dtRaw);
          if (dt === genDt) {
            const value = ReportChart.formatGenValue(genRaw);
            genSeries.push([genDt, value]);
            match = true;
            j++;
            if (inNullZone) {
              inNullZone = false;
              //if ( endMarker != startMarker ) {//NAVI - DAEG-177:removed the check as it would ignore single missing data point
              /* Create the zone */
              genNullBands.push({
                id: `band-${genNullBands.length}`,
                color: "#efc8cc",
                from: startMarker - FIFTEEN_MINUTES,
                to: endMarker + FIFTEEN_MINUTES,
                label: {
                  text: "No data"
                }
              });
              //  }
            }
          }
        }

        if (!match) {
          if (!inNullZone) {
            inNullZone = true;
            startMarker = dt;
          }
          endMarker = dt;
        }
      }
      if (inNullZone) {
        /* Create the zone */
        genNullBands.push({
          id: `band-${genNullBands.length}`,
          color: "#efc8cc",
          from: startMarker - FIFTEEN_MINUTES,
          to: endMarker + FIFTEEN_MINUTES,
          label: {
            text: "No data"
          }
        });
        //  }
      }
    }

    return {
      genSeries,
      priceSeries,
      midnightPlots,
      genNullBands,
      weekendBands
    };
  };

  closeChartView = () => {
    this.props.onChangeReportView(ReportViewTypes.Table);
  };

  render() {
    const { style, height, data, selectedTheme } = this.props;
    const isDarkTheme = selectedTheme === ThemeTypes.Dark;

    const ColorGeneration = "#7cb5ec";
    const ColorGenerationText = isDarkTheme ? "#7cb5ec" : "#0084ec";
    const ColorPrice = isDarkTheme ? "#eee" : "#636363";
    const ColorPriceText = isDarkTheme ? "#fff" : "#333";

    let chartTitle = "";
    let chartSubTitle = "";
    // let currentPrice = null;
    // let currentGeneration = null;

    if (data) {
      if (data && data.metaData) {
        const metaData = data.metaData;
        chartTitle = metaData.title;
        chartSubTitle = metaData.subtitle;
      }
      // if (data.currentPrice) currentPrice = data.currentPrice;
      //
      // if (data.currentGeneration) currentGeneration = data.currentGeneration;
    }
    const {
      genSeries,
      priceSeries,
      midnightPlots,
      genNullBands,
      weekendBands
    } = this.getSeriesData();
    //  console.log("ReportChart data", weekendBands);

    return (
      <div
        style={{
          border: `1px solid ${isDarkTheme ? "#222" : "#e8e8e8"} `,
          borderRadius: "3px",
          ...style
        }}
      >
        <div
          style={{
            backgroundColor: isDarkTheme ? "#333" : "#fff",
            height: "50px",
            padding: "8px",
            borderBottom: `2px solid ${isDarkTheme ? "#222" : "#e8e8e8"} `,
            textAlign: "right"
          }}
        >
          <span onClick={this.closeChartView} title={"Close chart view"}>
            <ButtonBorderless>
              <IconLight icon={"times"} style={{ fontSize: "large" }} />
            </ButtonBorderless>
          </span>
        </div>

        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={{
            title: {
              text: chartTitle,
              style: { color: isDarkTheme ? "#eee" : "#222" }
            },
            subtitle: {
              text: chartSubTitle,
              style: { color: isDarkTheme ? "#ddd" : "#777" }
            },
            tooltip: {
              split: false,
              shared: true
            },
            plotOptions: {
              line: {
                dataGrouping: {
                  enabled: false
                }
              },
              spline: {
                dataGrouping: {
                  enabled: false
                }
                // marker: {
                //   enabled: true,
                //   radius: 5
                // }
              },
              column: {
                dataGrouping: {
                  enabled: false
                }
              }
            },
            xAxis: [
              {
                type: "datetime",
                crosshair: true,
                title: {
                  text: "Settlement Date"
                },
                plotBands: weekendBands.concat(genNullBands),
                plotLines: midnightPlots
                // labels: {
                //   formatter: function() {
                //     return moment(this.value)
                //       .utcOffset("+1000")
                //       .format("ddd DD-MMM");
                //   }
                // }
                // ...data.xAxisOptions,
                // events: {
                //   setExtremes: function(event){
                //     if( event.trigger === 'navigator'&& (event.DOMEvent.DOMType === 'mousemove' || event.DOMEvent.type === 'mousemove')) //ignore if mouse is still moving
                //       return;
                //
                //     data.onXAxisUpdate(event.min, event.max);
                //     event.preventDefault();
                //   }
                // },
              }
            ],
            yAxis: [
              {
                opposite: false,
                gridLineColor: isDarkTheme ? "#222" : "#ccc",

                //  ...data.priceAxis,
                labels: {
                  style: {
                    color: ColorPriceText
                  },
                  // eslint-disable-next-line
                  format: "${value}"
                },
                title: {
                  text: "Price ($/MWh)",
                  style: {
                    color: ColorPriceText
                  }
                }
                // plotLines: [
                //   {
                //     color: ColorPriceText,
                //     width: 2,
                //     value: currentPrice,
                //     dashStyle: "dash",
                //     zIndex: 5,
                //     label: {
                //       text: `$${currentPrice}`,
                //       align: "left",
                //       //x: -55,
                //       style: {
                //         color: ColorPriceText
                //       }
                //     }
                //   }
                // ]
              },
              {
                // Secondary yAxis
                title: {
                  text: `Generation (MW)`,
                  style: {
                    color: ColorGenerationText
                  }
                },
                gridLineColor: isDarkTheme ? "#222" : "#ccc",
                //...data.generationAxis,
                labels: {
                  format: "{value}MW",
                  style: {
                    color: ColorGenerationText
                  }
                },
                opposite: true
                // plotLines: [
                //   {
                //     color: ColorGenerationText,
                //     width: 2,
                //     value: currentGeneration,
                //     dashStyle: "dash",
                //     zIndex: 5,
                //     label: {
                //       text: `${currentGeneration}MW`,
                //       align: "right",
                //       // x:65,
                //       style: {
                //         color: ColorGenerationText
                //       }
                //     }
                //   }
                // ]
              }
            ],
            series: [
              {
                name: "Generation",
                data: genSeries,
                type: "column",
                color: ColorGeneration,
                showInNavigator: true,
                navigatorOptions: {
                  type: "column"
                },
                tooltip: {
                  valueSuffix: "MW"
                },
                yAxis: 1
              },
              {
                name: "Price",
                data: priceSeries,
                type: "line",
                color: ColorPrice,
                lineWidth: 3,
                showInNavigator: true,
                tooltip: {
                  valuePrefix: "$"
                }
              }
            ],
            chart: {
              animation: true,
              zoomType: "xy",
              height: height,
              backgroundColor: isDarkTheme ? "#444" : "#fff",
              plotBackgroundColor: isDarkTheme ? "#333" : "#f9f9f9",
              textColor: isDarkTheme ? "#ddd" : "#1e4877"
            },
            rangeSelector: {
              enabled: true,
              verticalAlign: "top",
              buttonPosition: {
                align: "right",
                x: -70,
                y: 0
              },
              inputEnabled: false,
              allButtonsEnabled: true,
              buttons: [
                {
                  count: 6,
                  type: "hour",
                  text: "6h"
                },
                {
                  count: 12,
                  type: "hour",
                  text: "12h"
                },
                {
                  count: 24,
                  type: "hour",
                  text: "24h"
                },
                {
                  count: 48,
                  type: "hour",
                  text: "48h"
                },
                {
                  type: "all",
                  text: "all"
                }
              ]
            }
          }}
        />
      </div>
    );
  }
}

export default ReportChart;
