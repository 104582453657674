import React from "react";
import PropTypes from "prop-types";
import commaNumber from "comma-number";

const CellSummaryValue = ({ style, value }) => {
  return (
    <span style={style}>
      {value && <span>{commaNumber(Math.round(value))}</span>}
    </span>
  );
};

CellSummaryValue.propTypes = {
  style: PropTypes.object,
  value: PropTypes.number
};

export default CellSummaryValue;
