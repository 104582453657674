import React from "react";
import PropTypes from "prop-types";

import { ReportVisibilityTypes } from "./ReportVisibilityTypes";
import { ButtonGroupSingleSelection } from "../button_group_single_selection";

const ReportVisibilityOptions = [
  { text: "Visible", value: ReportVisibilityTypes.Visible, icon: "eye" },
  { text: "Hidden", value: ReportVisibilityTypes.Hidden, icon: "eye-slash" }
];

const ReportVisibilityPicker = ({ selectedValue, onChange, style }) => {
  return (
    <div style={style}>
      <ButtonGroupSingleSelection
        selectedValue={selectedValue}
        onChange={onChange}
        options={ReportVisibilityOptions}
      />
    </div>
  );
};

ReportVisibilityPicker.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object
};

export default ReportVisibilityPicker;
