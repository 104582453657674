import React, { Component } from "react";
import PropTypes from "prop-types";

import { Timer } from "../timer";
import moment from "moment/moment";
import { ThemeTypes } from "../theme_picker";
import { IconLight } from "../icon_light";

class ForecastPricesLink extends Component {
  state = {
    style: this.props.style,

    nem_datetime: "",
  };

  render() {
    const { selectedTheme } = this.props;

    const isDarkTheme = selectedTheme === ThemeTypes.Dark;

    return (
      <a
        href="https://pd4castr.com.au/waeg"
        target="_blank"
        style={{
          display: "inline-block",
          backgroundColor: isDarkTheme ? "#444" : "#fff",
          padding: "8px 16px",
          borderRadius: "4px",
          margin: "0 8px",
          // ...style
        }}
      >
        {/* <InfoTooltip content={tooltip} placement={"bottom"} />} */}
        {/* <label style={{ margin: "0 8px", color: "#999" }}>{label}</label> */}
        <span
          style={{
            fontWeight: "bold",
            color: isDarkTheme ? "#ddd" : "#31b5a8",
          }}
        >
          pd4castr
          <IconLight icon={"external-link"} style={{ marginLeft: "8px" }} />
        </span>
      </a>
    );
  }
}

ForecastPricesLink.propTypes = {
  style: PropTypes.object,
  dispatchInterval: PropTypes.string,
  runDateTime: PropTypes.string,
  onLogAction: PropTypes.func.isRequired,
  selectedTheme: PropTypes.string.isRequired,
};

export default ForecastPricesLink;
