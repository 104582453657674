import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

const Spinner = ({ style, message }) => (
  <div style={{ margin: "auto", textAlign: "center", ...style }}>
    <Spin size={"large"} /> {message && <h4>{message}</h4>}
  </div>
);

Spinner.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object
};

export default Spinner;
