import React from "react";
import PropTypes from "prop-types";

import { ReportSortTypes } from "./ReportSortTypes";
import { ButtonGroupSingleSelection } from "../button_group_single_selection";

const ReportSortOptions = [
  {
    text: "Alphabetically",
    value: ReportSortTypes.Alphabetically,
    icon: "sort-alpha-down"
  },
  {
    text: "Capacity",
    value: ReportSortTypes.Capacity,
    icon: "battery-half"
  },
  {
    text: "Portfolio",
    value: ReportSortTypes.Portfolio,
    icon: "university"
  },
  {
    text: "Fuel type",
    value: ReportSortTypes.FuelType,
    icon: "fire"
  }
];

const ReportSortPicker = ({ selectedValue, onChange, style }) => {
  return (
    <div style={style}>
      <ButtonGroupSingleSelection
        options={ReportSortOptions}
        selectedValue={selectedValue}
        onChange={onChange}
      />
    </div>
  );
};

ReportSortPicker.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object
};

export default ReportSortPicker;
