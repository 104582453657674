import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";

import { IconLight } from "../icon_light";

const ButtonGroupSingleSelection = ({
  options,
  selectedValue,
  onChange,
  style
}) => {
  const onChangeValue = e => {
    onChange(e.target.value);
  };

  return (
    <div style={style}>
      <Radio.Group value={selectedValue} onChange={onChangeValue}>
        {options.map(o => (
          <Radio.Button
            key={o.value}
            value={o.value}
            style={{ padding: "0 10px" }}
          >
            <IconLight title={o.text} icon={o.icon} />
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

ButtonGroupSingleSelection.propTypes = {
  style: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      icon: PropTypes.string
    })
  ).isRequired,
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ButtonGroupSingleSelection;
