import { SubscriptionStatus } from "./SubscriptionStatus";

class Subscription {
  _subscription;

  constructor(subscription) {
    this._subscription = subscription;
  }

  hasAccess(appName) {
    const status = this.getSubscription(appName);

    return status
      ? status === SubscriptionStatus.SUBSCRIBED ||
          status === SubscriptionStatus.TRIAL
      : false;
  }

  getSubscription(appName) {
    const item = this._subscription.filter(s => s.app_name === appName);
    return item && item.length > 0 ? item[0].subscription_status : null;
  }

  apps() {
    //process apps subscription data in heirarchial format
    let apps = {};

    this._subscription.forEach(i => {
      const app_group_name = i.app_group_name;
      const app_name = i.app_name;
      const app_url = i.app_url;
      const subscription_status = i.subscription_status;

      if (!apps[app_group_name]) apps[app_group_name] = [];

      apps[app_group_name].push({
        app_name: app_name,
        app_url: app_url,
        subscription_status: subscription_status
      });
    });

    return apps;
  }
}

export default Subscription;
