import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { ButtonGroupMultipleSelection } from "../../_components";

const ReportDaysPicker = ({
  numberOfDays,
  startDate,
  hiddenValues,
  onChange,
  style
}) => {
  //get day of week based on day index and start date
  const getDayOfWeek = (dayNumber, startDate) => {
    const m_startDate = moment(startDate).utcOffset("+1000");

    if (m_startDate) {
      const dt = m_startDate.add(dayNumber, "days");
      return dt.format("ddd");
    }

    return "---";
  };

  //construct days button options based on number of days and start date
  const getReportDaysOptions = (numberOfDays, startDate) => {
    let options = [];
    for (let i = 0; i < numberOfDays; i++) {
      options.push({
        title: `DAY ${i}`,
        value: i,
        sub: getDayOfWeek(i, startDate)
      });
    }
    return options;
  };

  const getSelectedValues = () => {
    let selectedValues = [];
    for (let i = 0; i < numberOfDays; i++) {
      //add only if not in hidden days
      if (hiddenValues.indexOf(i) < 0) selectedValues.push(i);
    }
    return selectedValues;
  };

  const onChangeSelectedValues = newSelectedValues => {
    let hiddenValues = [];
    for (let i = 0; i < numberOfDays; i++) {
      if (newSelectedValues.indexOf(i) < 0) hiddenValues.push(i);
    }

    onChange(hiddenValues);
  };

  return (
    <ButtonGroupMultipleSelection
      style={style}
      options={getReportDaysOptions(numberOfDays, startDate)}
      selectedValues={getSelectedValues()}
      onChange={onChangeSelectedValues}
    />
  );
};

ReportDaysPicker.propTypes = {
  numberOfDays: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  hiddenValues: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default ReportDaysPicker;
