import React from "react";
import PropTypes from "prop-types";

import { InfoTooltip } from "../../../index";

const CellGenGroupName = ({
  groupName,
  portfolio,
  capacity,
  fuelType,
  equation,
  onClick,
  style,
  linkColor
}) => {
  //get tooltip content
  const getTooltipContent = () => {
    return (
      <div>
        <div style={{ borderBottom: "1px solid #eee", marginBottom: "8px" }}>
          <b>{groupName}</b>
        </div>
        <table>
          <tbody>
            {equation && (
              <tr>
                <td>
                  <b>Equation:</b>
                </td>
                <td>{equation}</td>
              </tr>
            )}
            {portfolio && (
              <tr>
                <td>
                  <b>Portfolio:</b>
                </td>
                <td>{portfolio}</td>
              </tr>
            )}
            {capacity && (
              <tr>
                <td>
                  <b>Capacity:</b>
                </td>
                <td>{capacity} MW</td>
              </tr>
            )}
            {fuelType && (
              <tr>
                <td>
                  <b>Fuel type:</b>
                </td>
                <td>{fuelType}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <span style={style}>
      <InfoTooltip
        content={getTooltipContent()}
        placement={"right"}
        style={{ marginRight: "8px" }}
      />
      <span
        onClick={onClick}
        style={{
          cursor: "pointer",
          color: linkColor
        }}
      >
        {groupName}
      </span>
    </span>
  );
};

CellGenGroupName.propTypes = {
  style: PropTypes.object,
  groupName: PropTypes.string.isRequired,
  portfolio: PropTypes.string.isRequired,
  capacity: PropTypes.number.isRequired,
  fuelType: PropTypes.string.isRequired,
  equation: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  linkColor: PropTypes.string.isRequired
};

export default CellGenGroupName;
