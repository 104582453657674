import React from "react";
import PropTypes from "prop-types";

import { ButtonGroupMultipleSelection, IconLight } from "../../_components";
import { RegionTypes } from "./RegionTypes";

const Regions = [
  RegionTypes.NSW1,
  RegionTypes.VIC1,
  RegionTypes.QLD1,
  RegionTypes.SA1,
  RegionTypes.TAS1
];

const RegionPicker = ({ style, selectedValues, onChange, data }) => {
  const isSelected = value => {
    return selectedValues.indexOf(value) >= 0;
  };

  const getGroupDetails = r => {
    const groupCount = data[r] && data[r].groupCount;
    return (
      <span style={{ visibility: isSelected(r) ? "visible" : "hidden" }}>
        <span style={{ marginLeft: "4px", marginRight: "4px" }}>
          {groupCount ? groupCount.visible : "--"}
        </span>
        <IconLight icon={"eye"} />
        <span style={{ marginLeft: "8px", marginRight: "4px" }}>
          {groupCount ? groupCount.hidden : "--"}
        </span>
        <IconLight icon={"eye-slash"} />
      </span>
    );
  };

  const getOptions = () => {
    let options = [];
    Regions.forEach(r =>
      options.push({
        title: r,
        value: r,
        sub: getGroupDetails(r)
      })
    );

    return options;
  };
  return (
    <ButtonGroupMultipleSelection
      selectedValues={selectedValues}
      onChange={onChange}
      options={getOptions()}
      style={style}
    />
  );
};

RegionPicker.propTypes = {
  selectedValues: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  data: PropTypes.object
};

export default RegionPicker;
