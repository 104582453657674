import React, { Component } from "react";
import PropTypes from "prop-types";
import { Spinner } from "../_components";

class Login extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.auth.login();
  }

  render() {
    return <Spinner message={"Logging in"} />;
  }
}

export default Login;
