import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import moment from "moment";
import _ from "lodash";

import {
  RegionPicker,
  RegionTypes,
  ReportDaysPicker,
  ReportSortTypes,
  ReportViewTypes,
  ReportVisibilityTypes,
  Timer,
  TimerNem,
  TimerCountdown,
  TableRowTypes,
  TableGroupActions,
  ReportChart,
  ReportTable,
  ReportViewPicker,
  ThemePicker,
  ThemeTypes,
  ReportViewOptionsPicker,
  ReportViewOptionsTypes,
  ReportDownloadCsv,
} from "../_components";
import { ForecastPricesLink } from "../_components/forecast_prices_link";

//region  config
const TABLE_COLLAPSED_WIDTH = 520;
const IS_DEBUG = false; //enables console logging messages

const DATA_KEYS = {
  AvgGenForecastAtMaxPrices: "avg_gen_max_forecast_at_max_prices",
  GroupDetails: "group_details",
  NumberOfDays: "number_of_days",
  ForecastGroupGeneration: "forecast_group_generation",
  ForecastPrices: "forecast_prices",
  LatestDispatchPrice: "latest_dispatch_price",
  ForecastMaxPrices: "forecast_max_prices",
  LatestGroupGeneration: "latest_group_generation",
  CurrentDispatchInterval: "current_dispatch_interval",
  RunDatetime: "run_datetime",
  DownloadCsv: "downloadcsv",
  PublicationDatetime: "publication_datetime",
};

const DATA_PARAMS = {
  RegionIds: "region_ids",
  RunDateTime: "run_datetime",
  DayNumbers: "day_numbers",
  GroupIds: "group_ids",
  DispatchInterval: "dispatch_interval",
};

const DATA_ENDPOINTS = {
  [DATA_KEYS.ForecastMaxPrices]: {
    url: "/v2/web/pw7/forecastmaxprices",
    params: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
    requiredParams: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
  },
  [DATA_KEYS.AvgGenForecastAtMaxPrices]: {
    url: "/v2/web/pw7/avggenerationatforecastmaxprices",
    params: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
      //TODO other params
    ],
    requiredParams: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
  },
  [DATA_KEYS.GroupDetails]: {
    url: "/v2/web/pw7/groupdetails",
    params: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
    requiredParams: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
  },
  [DATA_KEYS.NumberOfDays]: {
    url: "/v2/web/pw7/numberofdays",
    params: [DATA_PARAMS.RunDateTime],
    triggers: [
      DATA_KEYS.GroupDetails,
      DATA_KEYS.AvgGenForecastAtMaxPrices,
      DATA_KEYS.ForecastMaxPrices,
      DATA_KEYS.ForecastPrices,
      DATA_KEYS.ForecastGroupGeneration,
      DATA_KEYS.LatestGroupGeneration,
    ],
    requiredParams: [DATA_PARAMS.RunDateTime],
  },
  [DATA_KEYS.ForecastGroupGeneration]: {
    url: "/v2/web/pw7/forecastgroupgeneration",
    params: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
    requiredParams: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
    // invokeCondition: {
    //   reportView: ReportViewTypes.Chart
    // }
  },
  [DATA_KEYS.DownloadCsv]: {
    url: "/auth/v2/web/pw7/downloadcsv",
    params: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
    requiredParams: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
  },
  [DATA_KEYS.ForecastPrices]: {
    url: "/v2/web/pw7/forecastprices",
    params: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
    requiredParams: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
    // invokeCondition: {
    //   reportView: ReportViewTypes.Chart
    // }
  },
  [DATA_KEYS.LatestDispatchPrice]: {
    url: "/v2/web/pw7/latestdispatchprice",
    params: [DATA_PARAMS.DispatchInterval, DATA_PARAMS.RegionIds],
    requiredParams: [DATA_PARAMS.DispatchInterval, DATA_PARAMS.RegionIds],
    invokeCondition: {
      reportView: ReportViewTypes.Table, //only invoke if table view is selected
    },
  },
  [DATA_KEYS.LatestGroupGeneration]: {
    url: "/v2/web/pw7/latestgroupgeneration",
    params: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.DispatchInterval,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
    requiredParams: [
      DATA_PARAMS.RunDateTime,
      DATA_PARAMS.DispatchInterval,
      DATA_PARAMS.RegionIds,
      DATA_PARAMS.DayNumbers,
    ],
    invokeCondition: {
      reportView: ReportViewTypes.Table,
    },
  },
  [DATA_KEYS.CurrentDispatchInterval]: {
    url: "/v2/web/pw7/currentdispatchinterval",
    params: [],
    triggers: [DATA_KEYS.LatestDispatchPrice, DATA_KEYS.LatestGroupGeneration], //numebr of days is null for latest group first time round
  },
  [DATA_KEYS.RunDatetime]: {
    url: "/v2/web/pw7/maxrundatetime",
    params: [],
    triggers: [
      DATA_KEYS.NumberOfDays, //number of days will trigger others
    ],
  },
  [DATA_KEYS.PublicationDatetime]: {
    url: "/v2/web/pw7/maxpublicationdatetime",
    params: [],
    triggers: [
      DATA_KEYS.NumberOfDays, //number of days will trigger others
    ],
  },
};
//endregion

class Summary extends Component {
  state = {
    settings: {
      selectedRegions: [RegionTypes.NSW1, RegionTypes.QLD1],
      selectedSort: ReportSortTypes.Alphabetically,
      selectedReportView: ReportViewTypes.Table,
      selectedReportViewOptions: [
        ReportViewOptionsTypes.Summary,
        ReportViewOptionsTypes.Sparkline,
      ],
      selectedReportVisibility: ReportVisibilityTypes.Visible,
      selectedChart: {
        groupId: "281913cbc6e556358690985784d7baa053d1029b",
        regionId: "NSW1",
      },
      //  hiddenGroupIds: {},
      // hiddenDays: [],
      selectedTheme: ThemeTypes.Light,
      ...this.props.settings,
    },
    data: {
      [DATA_KEYS.RunDatetime]: "",
      [DATA_KEYS.NumberOfDays]: {},
      [DATA_KEYS.GroupDetails]: {},
      [DATA_KEYS.ForecastMaxPrices]: {},
      [DATA_KEYS.AvgGenForecastAtMaxPrices]: {},
      [DATA_KEYS.ForecastGroupGeneration]: {},
      [DATA_KEYS.ForecastPrices]: {},
      [DATA_KEYS.LatestDispatchPrice]: {},
      [DATA_KEYS.LatestGroupGeneration]: {},
      [DATA_KEYS.CurrentDispatchInterval]: {},
      [DATA_KEYS.PublicationDatetime]: "",
    },
    tableHeight: Summary.getTableHeight(),
    csvFetching: {
      loading: false,
      error: false,
    },
  };

  componentDidMount() {
    this.props.onLogAction("", "load", { state: this.state });

    this.startDispatchPolling();
    this.startPw7Polling();
    //this.fetchAllData();//willbe fetched with polling ones.

    //on resize, reset table width and height
    window.onresize = () => {
      this.setState({
        tableHeight: Summary.getTableHeight(),
      });
    };
  }

  componentWillUnmount() {
    this.stopDispatchPolling();
    this.stopPw7Polling();
  }

  //region Log code

  logClickAction = (component, payload) => {
    this.props.onLogAction(component, "click", payload);
  };

  // logFetchAction = (component, payload) => {
  //   this.props.onLogAction(component, "fetch", payload);
  // };

  logError = (component, payload) => {
    this.props.onLogAction(component, "error", payload);
  };

  //endregion

  //region Data Fetching Code

  startDispatchPolling = () => {
    this.fetchDispatchData(); //fetch once and then start polling
    //poll dispatch data every  10 seconds
    this.dispatch_polling = setInterval(this.fetchDispatchData, 10000);
  };

  stopDispatchPolling = () => {
    clearInterval(this.dispatch_polling);
  };

  fetchDispatchData = () => {
    //console.log("DISPATCH POLLING");
    //get current dispatch interval
    this.fetchDataByKey(DATA_KEYS.CurrentDispatchInterval);
  };

  startPw7Polling = () => {
    this.fetchPw7Data(); //fetch once and then start polling
    //poll pw7 data every  60 seconds
    this.pw7_polling = setInterval(this.fetchPw7Data, 60000);
  };

  stopPw7Polling = () => {
    clearInterval(this.pw7_polling);
  };

  fetchPw7Data = () => {
    //console.log("PW7 POLLING");
    this.fetchDataByKey(DATA_KEYS.RunDatetime);
    this.fetchDataByKey(DATA_KEYS.PublicationDatetime);
  };
  
  fetchAllData = () => {
    if (IS_DEBUG) console.log("FETCHING ALL DATA");
    const {
      settings: { selectedReportView },
    } = this.state;

    //get number of days
    this.fetchDataByKey(DATA_KEYS.NumberOfDays);

    //get group details
    this.fetchDataByKey(DATA_KEYS.GroupDetails);

    if (selectedReportView === ReportViewTypes.Table) {
      // if table view, get summary data for selected days
      if (this.isSummaryOn()) {
        this.fetchDataByKey(DATA_KEYS.AvgGenForecastAtMaxPrices);

        //get region forecast max prices summary
        this.fetchDataByKey(DATA_KEYS.ForecastMaxPrices);
      }
      //get latest data
      this.fetchDataByKey(DATA_KEYS.LatestDispatchPrice);
      this.fetchDataByKey(DATA_KEYS.LatestGroupGeneration);

      //get sparkline data
      if (this.isSparklinesOn()) {
        this.fetchDataByKey(DATA_KEYS.ForecastGroupGeneration);
        this.fetchDataByKey(DATA_KEYS.ForecastPrices);
      }
    } else if (selectedReportView === ReportViewTypes.Chart) {
      this.fetchDataByKey(DATA_KEYS.ForecastGroupGeneration);
      this.fetchDataByKey(DATA_KEYS.ForecastPrices);
    }
  };

  getParamValue = (paramName) => {
    //todo only return days, regions and rundatetime if they are not fetched yet, would need to know what endpoints will be fetched too
    switch (paramName) {
      case DATA_PARAMS.RunDateTime:
        return this.getRunDateTime();      
      case DATA_PARAMS.PublicationDatetime:
        return this.getPublicationDateTime();
      case DATA_PARAMS.DayNumbers:
        return this.getSelectedReportDays().join(",");
      case DATA_PARAMS.RegionIds:
        return this.state.settings.selectedRegions.join(",");
      case DATA_PARAMS.GroupIds:
        return this.state.settings.selectedGroupId;
      case DATA_PARAMS.DispatchInterval:
        return this.getDispatchInterval();
      default:
        console.error(paramName + " param not implemented yet");
    }
  };

  getDataParams = (dataKey) => {
    let params = {};

    DATA_ENDPOINTS[dataKey].params.forEach(
      (p) => (params[p] = this.getParamValue(p))
    );
    return params;
  };

  fetchDataByKey = (dataKey) => {
    const { waegAPI } = this.props;
    if (IS_DEBUG) console.log("Fetching:", dataKey);
    //TODO implement check if data exists then not to re-fetch, example when toggling new region, don't fetch same regions
    //but initially leave it as is, can make it better later
    const params = this.getDataParams(dataKey);

    const def = DATA_ENDPOINTS[dataKey]; //get definition

    //check for invokeCondition if it exists and then if it matches
    let conditionMet = true;
    if (def.invokeCondition) {
      if (def.invokeCondition.reportView) {
        const selectedReportView = this.getSelectedReportView();
        if (selectedReportView !== def.invokeCondition.reportView)
          conditionMet = false;
      }
    }

    if (!conditionMet) {
      if (IS_DEBUG)
        console.warn(
          "Invoke condition not met, so ignoring data fetch request",
          dataKey
        );
      return;
    }

    //check if required params have values
    const requiredParams = def.requiredParams;

    for (let i = 0; requiredParams && i < requiredParams.length; i++) {
      const rp = requiredParams[i];
      if (
        params[rp] === null ||
        params[rp] === undefined ||
        params[rp] === ""
      ) {
        conditionMet = false;
        break;
      }
    }
    if (!conditionMet) {
      if (IS_DEBUG)
        console.warn(
          "Required params don't have a value, so ignoring data fetch request",
          dataKey
        );
      return;
    }

    waegAPI
      .get(def.url, {
        params: params,
      })
      .then((response) => {
        if (IS_DEBUG) console.log("Received response:" + dataKey);

        this.updateData(
          dataKey,
          this.processDataByKey(dataKey, response.data, params),
          def.triggers
        );
      })
      .catch((error) => {
        console.error("Fetching error:" + dataKey, error);
        //this.logError("DataFetching", { target: dataKey, error: error});
      });
  };

  //process raw data based on data key to its intended format
  processDataByKey = (dataKey, rawData, requestParams) => {
    let data = {};
    let run_datetime;
    let dispatch_interval;

    switch (dataKey) {
      // group details
      case DATA_KEYS.GroupDetails:
        data = {};
        run_datetime = requestParams[DATA_PARAMS.RunDateTime];
        if (!data[run_datetime]) data[run_datetime] = {}; //add run_datetime

        rawData.forEach((r) => {
          const region_id = r.region_id;
          const group_id = r.group_id;
          if (!data[run_datetime][region_id])
            data[run_datetime][region_id] = {}; //add region_id

          data[run_datetime][region_id][group_id] = {
            ...r,
            fuel: r.fuel ? r.fuel : "Unknown", //change null to unknown
            portfolio: r.portfolio ? r.portfolio : "Unknown", //change null to unknown
          };
        });
        return data;

      //table summary
      case DATA_KEYS.AvgGenForecastAtMaxPrices:
        data = {};
        run_datetime = requestParams[DATA_PARAMS.RunDateTime];

        if (!data[run_datetime]) data[run_datetime] = {}; //add run_datetime

        rawData.forEach((r) => {
          const region_id = r.region_id;
          const group_id = r.group_id;
          const day_number = r.day_number;

          if (!data[run_datetime][region_id])
            data[run_datetime][region_id] = {}; //add region_id

          if (!data[run_datetime][region_id][day_number])
            data[run_datetime][region_id][day_number] = {}; //add day number

          if (!data[run_datetime][region_id][day_number][group_id])
            data[run_datetime][region_id][day_number][group_id] = {}; //add group id

          data[run_datetime][region_id][day_number][group_id]["mw"] = r.mw;
        });
        return data;

      //table price summary
      case DATA_KEYS.ForecastMaxPrices:
        data = {};

        run_datetime = requestParams[DATA_PARAMS.RunDateTime];

        if (!data[run_datetime]) data[run_datetime] = {}; //add run_datetime

        rawData.forEach((r) => {
          const region_id = r.region_id;
          const day_number = r.day_number;

          if (!data[run_datetime][region_id])
            data[run_datetime][region_id] = {}; //add region_id

          if (!data[run_datetime][region_id][day_number])
            data[run_datetime][region_id][day_number] = {}; //add day number

          data[run_datetime][region_id][day_number]["max_price"] = r.max_price;
        });
        return data;

      //chart price data
      case DATA_KEYS.ForecastPrices:
        data = {};

        run_datetime = requestParams[DATA_PARAMS.RunDateTime];

        if (!data[run_datetime]) data[run_datetime] = {}; //add run_datetime

        rawData.forEach((r) => {
          const region_id = r.region_id;
          const day_number = r.day_number;

          if (!data[run_datetime][region_id])
            data[run_datetime][region_id] = {}; //add region_id

          if (!data[run_datetime][region_id][day_number])
            data[run_datetime][region_id][day_number] = {}; //add day number

          if (!data[run_datetime][region_id][day_number]["data"])
            data[run_datetime][region_id][day_number]["data"] = []; //add empty data array

          data[run_datetime][region_id][day_number]["data"].push({
            settlementdate: r.interval_datetime,
            price: r.price, //add to data array with sett date and value
          });
        });
        return data;

      //chart generation data
      case DATA_KEYS.ForecastGroupGeneration:
        data = {};

        run_datetime = requestParams[DATA_PARAMS.RunDateTime];

        if (!data[run_datetime]) data[run_datetime] = {}; //add run_datetime

        rawData.forEach((r) => {
          const region_id = r.region_id;
          const group_id = r.group_id;
          const day_number = r.day_number;

          if (!data[run_datetime][region_id])
            data[run_datetime][region_id] = {}; //add region_id

          if (!data[run_datetime][region_id][day_number])
            data[run_datetime][region_id][day_number] = {}; //add day number

          if (!data[run_datetime][region_id][day_number][group_id])
            data[run_datetime][region_id][day_number][group_id] = {}; //add group id

          if (!data[run_datetime][region_id][day_number][group_id]["data"])
            data[run_datetime][region_id][day_number][group_id]["data"] = []; //add empty data array

          data[run_datetime][region_id][day_number][group_id]["data"].push({
            settlementdate: r.settlementdate,
            expected_generation: r.expected_generation, //add to data array with sett date and value
          });
        });
        return data;

      case DATA_KEYS.LatestGroupGeneration:
        data = {};

        dispatch_interval = requestParams[DATA_PARAMS.DispatchInterval];
        run_datetime = requestParams[DATA_PARAMS.RunDateTime];

        if (!data[run_datetime]) data[run_datetime] = {}; //add run_datetime

        if (!data[run_datetime][dispatch_interval])
          data[run_datetime][dispatch_interval] = {}; //add dispatch interval

        rawData.forEach((r) => {
          const region_id = r.region_id;
          const group_id = r.group_id;

          if (!data[run_datetime][dispatch_interval][region_id])
            data[run_datetime][dispatch_interval][region_id] = {}; //add region_id

          if (!data[run_datetime][dispatch_interval][region_id][group_id])
            data[run_datetime][dispatch_interval][region_id][group_id] = {}; //add group_id

          data[run_datetime][dispatch_interval][region_id][group_id]["mw"] =
            r.mw;
        });

        return data;

      case DATA_KEYS.LatestDispatchPrice:
        data = {};

        dispatch_interval = requestParams[DATA_PARAMS.DispatchInterval];
        if (!data[dispatch_interval]) data[dispatch_interval] = {}; // add dispatch interval

        rawData.forEach((r) => {
          const region_id = r.region_id;
          if (!data[dispatch_interval][region_id])
            data[dispatch_interval][region_id] = {}; // add region id

          data[dispatch_interval][region_id]["price"] = r.price;
        });

        return data;

      case DATA_KEYS.CurrentDispatchInterval:
        data = {};
        data["T"] = rawData.dispatch_interval;
        return data;

      case DATA_KEYS.RunDatetime:
        data = {};
        data["T"] = rawData.max_run_datetime;
        return data;

      case DATA_KEYS.PublicationDatetime:
        data = {};
        data["T"] = rawData.max_publication_datetime;
        data.is_processing = !rawData.is_completed;
        return data;

      case DATA_KEYS.NumberOfDays:
        data = {};
        run_datetime = requestParams[DATA_PARAMS.RunDateTime];

        if (!data[run_datetime]) data[run_datetime] = {}; //add run_datetime

        data[run_datetime] = rawData;

        return data;

      default:
        //return as is
        return rawData;
    }
  };
  //endregion

  //region Actions

  updateSettings = (key, refetchData) => (newValue) => {
    this.setState(
      (prevState) => ({
        settings: {
          ...prevState.settings,
          [key]: newValue,
        },
      }),

      () => {
        if (refetchData) this.fetchAllData();
        this.props.onUpdateSettings(this.state.settings); //update parent}
      }
    );
  };

  updateData = (key, value, apiTriggers) => {
    //only update if value has changed
    //as in case on polling function
    if (!_.isEqual(this.state.data[key], value)) {
      this.setState(
        (prevState) => ({
          data: {
            ...prevState.data,
            [key]: value,
          },
        }),
        () => {
          if (apiTriggers) {
            if (IS_DEBUG) console.log("Invoking triggers", apiTriggers);
            //trigger other api endpoints after this one is updated
            //ensure only happens when parent data has changed, ie. rundatetimes
            apiTriggers.forEach((trigger) => this.fetchDataByKey(trigger));
          }
        }
      );
    }
  };

  //on change selected regions
  onChangeSelectedRegions = (newValue) => {
    this.updateSettings("selectedRegions", true)(newValue);
    this.logClickAction("selectedRegions", { target: newValue.join(",") });
  };

  //on change report view options
  onChangeReportViewOptions = (newValue) => {
    this.updateSettings("selectedReportViewOptions", true)(newValue);
    this.logClickAction("selectedReportViewOptions", {
      target: newValue.join(","),
    });
  };

  //on change hidden days
  onChangeHiddenDays = (newValue) => {
    this.updateSettings("hiddenDays", true)(newValue);
    this.logClickAction("hiddenDays", { target: newValue.join(",") });
  };

  //on change sort
  onChangeSort = (newValue) => {
    this.updateSettings("selectedSort", false)(newValue);
    this.logClickAction("sort", { target: newValue });
  };

  //on change report view
  onChangeReportView = (newValue) => {
    this.updateSettings("selectedReportView", true)(newValue);
    this.logClickAction("reportView", { target: newValue });
  };

  //on change theme
  onChangeTheme = (newValue) => {
    this.updateSettings("selectedTheme", false)(newValue);
    this.logClickAction("theme", { target: newValue });
  };

  //on change report visibility
  onChangeReportVisibility = (newValue) => {
    this.updateSettings("selectedReportVisibility", false)(newValue);
    this.logClickAction("reportVisibility", { target: newValue });
  };

  onDownloadCSV = () => {
    const { waegAPI } = this.props;

    const self = this;
    self.setState(
      {
        csvFetching: {
          loading: true,
          error: false,
        },
      },
      () =>
        waegAPI
          .get("/auth/v2/web/pw7/downloadcsv", {
            params: this.getDataParams(DATA_KEYS.DownloadCsv),
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "WAEG.csv");
            document.body.appendChild(link);
            link.click();
            self.setState({
              csvFetching: {
                loading: false,
                error: false,
              },
            });
          })
          .catch((error) => {
            console.error("Fetching error: download csv", error);
            self.setState({
              csvFetching: {
                loading: false,
                error: true,
              },
            });
          })
    );
    this.logClickAction("download_csv", {
      params: this.getDataParams(DATA_KEYS.DownloadCsv),
    });
  };

  //on action for given group id
  onGroupAction = (action, regionId, groupId) => {
    if (IS_DEBUG) console.log("GroupAction", action, regionId, groupId);
    switch (action) {
      case TableGroupActions.Hide:
        this.hideGroupId(regionId, groupId);
        break;
      case TableGroupActions.Show:
        this.showGroupId(regionId, groupId);
        break;
      case TableGroupActions.ShowAll:
        this.showAllGroupsInRegionId(regionId);
        break;
      case TableGroupActions.Select:
        this.selectChart(regionId, groupId);
        break;
      default:
        console.error(`Group action: ${action} not implemented yet`);
    }
    this.logClickAction(action, { target: `${regionId}-${groupId}` });
  };

  //update selectedChart in settings with regionId and groupId
  selectChart = (regionId, groupId) => {
    this.updateSettings(
      "selectedChart",
      false
    )({
      regionId: regionId,
      groupId: groupId,
    });
  };

  //add group id to hiddenGroupIds list in settings
  hideGroupId = (regionId, groupId) => {
    // console.log("hideGroupId", regionId, groupId);
    const allHiddenGroupIds = this.getAllHiddenGroups();
    const regionHiddenGroupIds = allHiddenGroupIds[regionId];
    if (!regionHiddenGroupIds) allHiddenGroupIds[regionId] = {};

    allHiddenGroupIds[regionId][groupId] = new Date().getTime(); //record time when it was hidden

    this.updateSettings("hiddenGroupIds", false)(allHiddenGroupIds);
  };

  //remove group id from hiddenGroupIds list in settings
  showGroupId = (regionId, groupId) => {
    const allHiddenGroupIds = this.getAllHiddenGroups();

    if (
      allHiddenGroupIds &&
      allHiddenGroupIds[regionId] &&
      allHiddenGroupIds[regionId][groupId]
    )
      delete allHiddenGroupIds[regionId][groupId]; //remove key from hidden groups list

    this.updateSettings("hiddenGroupIds", false)(allHiddenGroupIds);
  };

  //remove all hidden groupIds in a region
  showAllGroupsInRegionId = (regionId) => {
    const allHiddenGroupIds = this.getAllHiddenGroups();

    if (allHiddenGroupIds && allHiddenGroupIds[regionId])
      delete allHiddenGroupIds[regionId]; //remove region id with all hidden groups ids key inside it
    //NOTE that this will remove all groups in hidden even the ones that don't exists in current rundt.

    this.updateSettings("hiddenGroupIds", false)(allHiddenGroupIds);
  };

  //endregion

  //region State helpers

  //get selected chart
  getSelectedChart = () => {
    return this.state.settings.selectedChart;
  };

  //get data from state, as fetched from API, based on data key
  getDataByKey = (dataKey) => {
    return this.state.data[dataKey];
  };

  //get total number of days for the rundatetime
  getNumberOfDays = () => {
    const data = this.getDataByKey(DATA_KEYS.NumberOfDays);
    const runDateTime = this.getRunDateTime();
    if (data && data[runDateTime] && data[runDateTime].number_of_days)
      return data[runDateTime].number_of_days;
    return 0;
  };

  //get currently selected pw7 rundatetime
  getRunDateTime = () => {
    return (
      this.state.data[DATA_KEYS.RunDatetime] &&
      this.state.data[DATA_KEYS.RunDatetime].T
    );
  };

  //get latest nemweb publication datetime
  getPublicationDateTime = () => {
    return (
      this.state.data[DATA_KEYS.PublicationDatetime] &&
      this.state.data[DATA_KEYS.PublicationDatetime].T
    );
  };

  //get run currently processing
  getIsRunProcessing = () => {
    return (
      this.state.data[DATA_KEYS.PublicationDatetime] &&
      this.state.data[DATA_KEYS.PublicationDatetime].is_processing
    );
  };

  //get currenlty selected dispatch interval datetime
  getDispatchInterval = () => {
    return (
      this.state.data.current_dispatch_interval &&
      this.state.data.current_dispatch_interval.T
    );
  };

  //get selected report  view options
  getSelectedReportViewOptions = () => {
    return this.state.settings["selectedReportViewOptions"]
      ? this.state.settings["selectedReportViewOptions"]
      : [];
  };

  //get hidden days from settings
  getHiddenReportDays = () => {
    return this.state.settings.hiddenDays ? this.state.settings.hiddenDays : [];
  };

  //return objects of hidden group ids for each region with array
  getAllHiddenGroups = () => {
    return this.state.settings.hiddenGroupIds
      ? this.state.settings.hiddenGroupIds
      : {};
  };

  //return hidden groups as array for given region
  getHiddenGroupInRegion = (regionId) => {
    const {
      settings: { hiddenGroupIds },
    } = this.state;
    const groupDetailsRegion = this.getGenGroupsDetails(regionId);
    if (hiddenGroupIds && hiddenGroupIds[regionId] && groupDetailsRegion) {
      //filter hidden groups only that exists for current rundt

      const hiddenGroupIdsRegion = Object.keys(hiddenGroupIds[regionId]); //as read from settings
      const groupIdsRegion = Object.keys(groupDetailsRegion);
      const filteredHiddenGroupIdsInRegion = hiddenGroupIdsRegion.filter(
        (i) => groupIdsRegion.indexOf(i) >= 0
      );
      return filteredHiddenGroupIdsInRegion;
    }

    return [];
  };

  //return count of visible, hidden and total group counts in a region
  getGroupCountsInRegion = (regionId) => {
    const groupDetailsRegion = this.getGenGroupsDetails(regionId);
    const totalGroupsCount = Object.keys(groupDetailsRegion).length;
    const hiddenGroupsCount = this.getHiddenGroupInRegion(regionId).length;

    return {
      visible:
        totalGroupsCount === 0 ? 0 : totalGroupsCount - hiddenGroupsCount, //if total not loaded then, return 0 for visible
      hidden: hiddenGroupsCount,
      total: totalGroupsCount,
    };
  };

  //get array of selected regions
  getSelectedRegions = () => {
    const {
      settings: { selectedRegions },
    } = this.state;

    return selectedRegions;
  };

  getRegionPickerData = () => {
    const selectedRegions = this.getSelectedRegions();

    let data = {};
    selectedRegions.forEach((r) => {
      const groupCount = this.getGroupCountsInRegion(r);

      data[r] = { groupCount: { ...groupCount } };
    });

    return data;
  };

  getSelectedReportView = () => {
    const {
      settings: { selectedReportView },
    } = this.state;
    return selectedReportView;
  };

  //get array of selected days, in order
  getSelectedReportDays = () => {
    const hiddenDays = this.getHiddenReportDays();
    const number_of_days = this.getNumberOfDays();
    let selectedReportDays = [];
    for (let i = 0; i < number_of_days; i++) {
      //add only if not in hidden days
      if (hiddenDays.indexOf(i) < 0) selectedReportDays.push(i);
    }
    return selectedReportDays.sort();
  };

  isSparklinesOn = () => {
    const selectedReportViewOptions = this.getSelectedReportViewOptions();
    const selectedReportView = this.getSelectedReportView();
    return (
      selectedReportView === ReportViewTypes.Table &&
      selectedReportViewOptions.indexOf(ReportViewOptionsTypes.Sparkline) >= 0
    );
  };

  isSummaryOn = () => {
    const selectedReportViewOptions = this.getSelectedReportViewOptions();
    const selectedReportView = this.getSelectedReportView();
    return (
      selectedReportView === ReportViewTypes.Table &&
      selectedReportViewOptions.indexOf(ReportViewOptionsTypes.Summary) >= 0
    );
  };

  //endregion

  //region Chart data processing

  //get group forecast data for chart view
  getChartGroupData = (run_datetime, regionId, days, groupId) => {
    let data = [];
    const forecastGroupData = this.getDataByKey(
      DATA_KEYS.ForecastGroupGeneration
    );

    if (
      forecastGroupData &&
      forecastGroupData[run_datetime] &&
      forecastGroupData[run_datetime][regionId]
    ) {
      const forecastGroupDataRegion = forecastGroupData[run_datetime][regionId];

      days.forEach((day) => {
        if (forecastGroupDataRegion[day]) {
          //if data exists for the day, otherwise may be loading or null
          const dayData = forecastGroupDataRegion[day];
          if (dayData && dayData[groupId] && dayData[groupId].data) {
            const groupData = dayData[groupId].data;
            data = data.concat(groupData);
          }
        }
      });
    }

    return { groupId: groupId, data: data };
  };

  //get region forecast data for chart view
  getChartRegionData = (run_datetime, regionId, days) => {
    let data = [];

    const forecastPriceData = this.getDataByKey(DATA_KEYS.ForecastPrices);

    if (
      forecastPriceData &&
      forecastPriceData[run_datetime] &&
      forecastPriceData[run_datetime][regionId]
    ) {
      const forecastPriceDataRegion = forecastPriceData[run_datetime][regionId];

      days.forEach((day) => {
        if (forecastPriceDataRegion[day]) {
          //if data exists for the day
          const dayData = forecastPriceDataRegion[day];
          if (dayData && dayData.data) {
            data = data.concat(dayData.data);
          }
        }
      });
    }

    return { regionId: regionId, data: data };
  };

  static getDispayDateTime(dt, format) {
    if (dt && format) {
      return moment(dt)
        .utcOffset("+1000")
        .format(format);
    }
    return dt;
  }

  //get chart meta data like title, subtitle
  getChartMetaData = (run_datetime, regionId, groupId) => {
    const genGroupDetailsRegion = this.getGenGroupsDetails(regionId);
    let chartMetaData = {};
    if (genGroupDetailsRegion) {
      const groupDetails = genGroupDetailsRegion[groupId];
      if (groupDetails && groupDetails.group_name)
        chartMetaData.title = `${
          groupDetails.group_name
        } (${regionId.toUpperCase()})`;
    }
    if (run_datetime) {
      chartMetaData.subtitle = `Forecast Generation at pd7day run time T = ${Summary.getDispayDateTime(
        run_datetime,
        "DD-MM-YYYY HH:mm"
      )}`;
    }
    return chartMetaData;
  };

  //get chart data
  getChartData = () => {
    const chartData = this.getSelectedChart();
    const { regionId, groupId } = chartData;
    const run_datetime = this.getRunDateTime();
    const days = this.getSelectedReportDays();

    const groupData = this.getChartGroupData(
      run_datetime,
      regionId,
      days,
      groupId
    );
    const regionData = this.getChartRegionData(run_datetime, regionId, days);
    const metaData = this.getChartMetaData(run_datetime, regionId, groupId);
    // const currentPrice = this.getRegionCurrentPrice(regionId);
    // const currentGeneration = this.getGroupCurrentGeneration(regionId, groupId);

    return {
      groupData: groupData,
      regionData: regionData,
      // currentPrice: currentPrice,
      // currentGeneration: currentGeneration,
      metaData: metaData,
    };
  };

  //endregion

  //region Table data processing

  //calculate table height based on window height and other components above it
  //for now, just doing fixed size for top components
  static getTableHeight() {
    return window.innerHeight - 160;
  }

  //sort rows alphabetically by group name
  static sortRowsByGroupName = (rows) => {
    return rows.sort((a, b) => {
      const nameA = a["group_name"];
      const nameB = b["group_name"];
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  };

  //sort rows by capacity from high to low
  static sortRowsByCapacity = (rows) => {
    //sort by capacity
    return rows.sort((a, b) => {
      const capA = a["capacity"];
      const capB = b["capacity"];
      if (capA < capB) return 1;
      if (capA > capB) return -1;
      return 0;
    });
  };

  getGenGroupsDetails = (region) => {
    const groupDetails = this.getDataByKey(DATA_KEYS.GroupDetails);
    const run_datetime = this.getRunDateTime();

    const groupDetailsRunDt = groupDetails && groupDetails[run_datetime];

    if (groupDetailsRunDt && groupDetailsRunDt[region])
      return groupDetailsRunDt[region];

    return {};
  };

  getGroupData = (region, groupId) => {
    let groupData = {};
    if (region && groupId) {
      //forecast data
      const data = this.getDataByKey(DATA_KEYS.AvgGenForecastAtMaxPrices);
      const run_datetime = this.getRunDateTime();
      const days = this.getSelectedReportDays();

      const dataRunDt = data[run_datetime];
      if (dataRunDt && dataRunDt[region]) {
        const dataRegion = dataRunDt[region];

        const selectedDays = this.getSelectedReportDays();

        selectedDays.forEach((day) => {
          const dayData = dataRegion[day];
          if (dayData && dayData[groupId])
            groupData[`day-${day}`] = dayData[groupId];
        });
      }

      //now data
      if (!groupData["now"]) groupData["now"] = {};
      groupData["now"]["mw"] = this.getGroupCurrentGeneration(region, groupId);

      //forecast detailed data
      if (!groupData["forecast"]) groupData["forecast"] = {};

      const chartData = this.getChartGroupData(
        run_datetime,
        region,
        days,
        groupId
      );
      if (chartData && chartData.data) groupData["forecast"] = chartData.data;
    }
    return groupData;
  };

  //get current generation for given  groupid
  getGroupCurrentGeneration = (region, groupId) => {
    const dataNow = this.getDataByKey(DATA_KEYS.LatestGroupGeneration);
    const dispatch_interval = this.getDispatchInterval();
    const run_datetime = this.getRunDateTime();

    if (
      dataNow &&
      dataNow[run_datetime] &&
      dataNow[run_datetime][dispatch_interval] &&
      dataNow[run_datetime][dispatch_interval][region] &&
      dataNow[run_datetime][dispatch_interval][region][groupId]
    ) {
      return dataNow[run_datetime][dispatch_interval][region][groupId].mw;
    }
    return null;
  };

  //get current price for given region
  getRegionCurrentPrice = (regionId) => {
    if (regionId) {
      const dataNow = this.getDataByKey(DATA_KEYS.LatestDispatchPrice);
      const dispatch_interval = this.getDispatchInterval();
      if (
        dataNow &&
        dataNow[dispatch_interval] &&
        dataNow[dispatch_interval][regionId]
      )
        return dataNow[dispatch_interval][regionId].price;
    }

    return null;
  };

  //get data for region  row like price
  getRegionRowData = (regionId) => {
    let rowData = {};
    if (regionId) {
      //add summary days  col data
      const data = this.getDataByKey(DATA_KEYS.ForecastMaxPrices);
      const run_datetime = this.getRunDateTime();
      const days = this.getSelectedReportDays();

      const dataRunDt = data[run_datetime];
      if (dataRunDt && dataRunDt[regionId]) {
        const dataRegion = dataRunDt[regionId];

        days.forEach((day) => {
          const dayData = dataRegion[day];
          if (dayData) rowData[`day-${day}`] = dayData;
        });
      }

      //add now col data
      rowData["price"] = this.getRegionCurrentPrice(regionId);

      //forecast detailed data
      if (!rowData["forecast"]) rowData["forecast"] = {};

      const chartData = this.getChartRegionData(run_datetime, regionId, days);

      if (chartData && chartData.data) rowData["forecast"] = chartData.data;
    }
    return rowData;
  };

  //get list of buckets (portfolios/fuel) from given groupDetails for a region
  getAllBuckets = (groupDetailsRegion, key) => {
    let buckets = [];
    const genGroupIds = Object.keys(groupDetailsRegion);
    genGroupIds.forEach((groupId) => {
      const groupDetails = groupDetailsRegion[groupId];
      const bucket = groupDetails[key];
      if (buckets.indexOf(bucket) < 0) buckets.push(bucket);
    });

    return buckets;
  };

  //get bucket data incl. gen group rows for region, filtered by portfolio and fuel keys
  getBucketData = (region, filterKey, filterValue) => {
    let data = {};
    if (!region) return data;

    const {
      settings: { selectedReportVisibility },
    } = this.state;

    const groupDetailsRegion = this.getGenGroupsDetails(region);
    const genGroupIds = Object.keys(groupDetailsRegion);
    const hiddenGroupIds = this.getHiddenGroupInRegion(region);

    let rows = [];
    genGroupIds.forEach((groupId) => {
      const groupDetails = groupDetailsRegion[groupId];
      if (filterKey && filterValue !== groupDetails[filterKey]) return; //skip if portfolio/fuel is specified and no match

      const groupData = this.getGroupData(region, groupId);
      const groupDataDays = Object.keys(groupData);
      if (
        (selectedReportVisibility === ReportVisibilityTypes.Visible &&
          hiddenGroupIds.indexOf(groupId) < 0) ||
        (selectedReportVisibility === ReportVisibilityTypes.Hidden &&
          hiddenGroupIds.indexOf(groupId) >= 0)
      )
        // if not in hidden group
        rows.push({
          rowType: TableRowTypes.GenGroup,
          key: groupId,
          group_id: groupId,
          ...groupDetails,
          ...groupData,
        });

      //calculate totals
      groupDataDays.forEach((d) => {
        const dayData = groupData[d];
        if (dayData) {
          if (!data[d]) data[d] = {};

          const keys = Object.keys(dayData);
          keys.forEach((k) => {
            if (!data[d][k]) data[d][k] = 0; //init as zero
            if (dayData[k])
              //if value is not NaN
              data[d][k] += dayData[k]; //sum total for region row
          });
        }
      });
    });

    return { data: data, rows: Summary.sortRowsByGroupName(rows) };
  };

  //construct data in format to be used by report view table
  getTableRows = () => {
    const {
      settings: { selectedRegions, selectedSort },
    } = this.state;

    let rows = [];

    selectedRegions.forEach((regionId) => {
      const groupDetailsRegion = this.getGenGroupsDetails(regionId);

      //build bucket rows
      let bucketRows;

      if (selectedSort === ReportSortTypes.Alphabetically) {
        //no bucket, just sort
        const bucketData = this.getBucketData(regionId, null, null);
        bucketRows = bucketData.rows;
      } else if (selectedSort === ReportSortTypes.Capacity) {
        //no bucket, just sort on capacity
        const bucketData = this.getBucketData(regionId, null, null);
        bucketRows = bucketData.rows;
        bucketRows = Summary.sortRowsByCapacity(bucketRows);
      } else if (
        selectedSort === ReportSortTypes.Portfolio ||
        selectedSort === ReportSortTypes.FuelType
      ) {
        let key = "portfolio";
        let rowType = TableRowTypes.Portfolio;
        if (selectedSort === ReportSortTypes.FuelType) {
          key = "fuel";
          rowType = TableRowTypes.Fuel;
        }
        const allBuckets = this.getAllBuckets(groupDetailsRegion, key);
        allBuckets.sort((a, b) => {
          //sort unknown as last, rest alphabetically
          if (a === "Unknown") return 1;
          if (b === "Unknown") return -1;
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
        bucketRows = [];
        allBuckets.forEach((b) => {
          const bucketData = this.getBucketData(regionId, key, b);
          if (bucketData.rows && bucketData.rows.length > 0) {
            //add portfolio/fuel row
            bucketRows.push({
              rowType: rowType,
              key: `${regionId}-${b}`,
              [key]: `${b} (${regionId})`,
              ...bucketData.data,
            });
            //concat group rows for above portfolio/fuel
            bucketRows = bucketRows.concat(bucketData.rows);
          }
        });
      }

      //push region row
      rows.push({
        rowType: TableRowTypes.Region,
        region_id: regionId,
        key: regionId,
        groupCount: {
          ...this.getGroupCountsInRegion(regionId),
        },
      });

      //push region price row
      // rows.push({
      //   rowType: TableRowTypes.ForecastPrices,
      //   key: `forecast-prices-${regionId}`,
      //   ...this.getRegionRowData(regionId),
      // });

      //push region price row
      rows.push({
        rowType: TableRowTypes.RegionPrice,
        key: `price-${regionId}`,
        ...this.getRegionRowData(regionId),
      });

      //push region groups rows
      rows = rows.concat(bucketRows);
    });

    return rows;
  };

  //endregion

  //region Render
  render() {
    const {
      settings: {
        selectedSort,
        selectedReportView,
        selectedReportVisibility,
        selectedTheme,
      },
      tableHeight,
      csvFetching,
    } = this.state;
    const dispatchInterval = this.getDispatchInterval();
    const runDateTime = this.getRunDateTime();
    const publicationDateTime = this.getPublicationDateTime();
    const isRunProcessing = this.getIsRunProcessing();
    if (IS_DEBUG) console.log("RENDER STATE", this.state);

    const selectedReportDays = this.getSelectedReportDays();
    return (
      <div style={{ padding: "8px" }}>
        <Row style={{ marginBottom: "8px" }}>
          <Col md={24}>
            <div style={{ textAlign: "center" }}>
              <ForecastPricesLink />

              <TimerNem
                dispatchInterval={dispatchInterval}
                runDateTime={runDateTime}
                onLogAction={this.props.onLogAction}
                selectedTheme={selectedTheme}
              />
              <Timer
                datetime={dispatchInterval}
                label={"DI ENDING"}
                format={"HH:mm"}
                tooltip={
                  "Current generation is at the beginning of DISPATCH INTERVAL ENDING"
                }
                selectedTheme={selectedTheme}
              />
              <TimerCountdown
                datetime={runDateTime}
                expire={60}
                label={"PD7DAY RUNTIME"}
                format={"HH:mm"}
                tooltip={
                  "The rundatetime of WAEG input data.  This timestamp is one of three fixed times per day, those being the 7:30, 13:00 and 18:00 p7day run.   This cell turns BLUE for the first hour after WAEG updates.  During this time the blue turns progressively lighter."
                }
                selectedTheme={selectedTheme}
              />
              <Timer
                datetime={publicationDateTime}
                label={"DATA PUBLICATION"}
                format={"HH:mm"}
                tooltip={
                  "The publication time of the input files to WAEG on AEMO's nemweb. This cell turns Orange from the publication time until the solver run completes and WAEG updates, therefore ORANGE means an update to WAEG is pending because the input data has been published but the solver has not finished updating WAEG data."
                }
                alert={isRunProcessing}
                alertColour={"#ffc069"}
                selectedTheme={selectedTheme}
              />
              <ReportViewPicker
                style={{ display: "inline-block", marginLeft: "8px" }}
                onChange={this.onChangeReportView}
                selectedValue={selectedReportView}
              />
              <ThemePicker
                style={{ display: "inline-block", marginLeft: "8px" }}
                onChange={this.onChangeTheme}
                selectedValue={selectedTheme}
              />
              <ReportDownloadCsv
                style={{ marginLeft: "8px" }}
                onClick={this.onDownloadCSV}
                isLoading={csvFetching.loading}
                hasError={csvFetching.error}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
          <Col md={24} style={{ textAlign: "center" }}>
            <RegionPicker
              style={{ textAlign: "center", display: "inline-block" }}
              selectedValues={this.getSelectedRegions()}
              data={this.getRegionPickerData()}
              onChange={this.onChangeSelectedRegions}
            />
            {/*</Col>*/}
            {/*<Col md={12}>*/}
            <ReportDaysPicker
              style={{
                textAlign: "center",
                display: "inline-block",
                marginLeft: "16px",
              }}
              numberOfDays={this.getNumberOfDays()}
              startDate={this.getRunDateTime()}
              hiddenValues={this.getHiddenReportDays()}
              onChange={this.onChangeHiddenDays}
            />
            <ReportViewOptionsPicker
              style={{
                textAlign: "center",
                display: "inline-block",
                marginLeft: "16px",
              }}
              selectedValues={this.getSelectedReportViewOptions()}
              onChange={this.onChangeReportViewOptions}
            />
          </Col>
        </Row>
        <ReportTable
          rows={this.getTableRows()}
          isLoading={false}
          selectedReportVisibility={selectedReportVisibility}
          onChangeReportVisibility={this.onChangeReportVisibility}
          selectedSort={selectedSort}
          onChangeSort={this.onChangeSort}
          selectedReportView={selectedReportView}
          onChangeReportView={this.onChangeReportView}
          showSummary={this.isSummaryOn()}
          showSparklines={this.isSparklinesOn()}
          showNow={selectedReportView === ReportViewTypes.Table}
          style={{
            display: "inline-block",
            width:
              selectedReportView === ReportViewTypes.Table
                ? "100%"
                : TABLE_COLLAPSED_WIDTH,
          }}
          tableHeight={tableHeight}
          selectedReportDays={selectedReportDays}
          onGroupAction={this.onGroupAction}
          startDate={this.getRunDateTime()}
          selectedChart={this.getSelectedChart()}
          selectedTheme={selectedTheme}
        />
        {selectedReportView === ReportViewTypes.Chart && (
          <div
            style={{
              display: "inline-block",
              width: window.innerWidth - (130 + TABLE_COLLAPSED_WIDTH),
              // marginLeft: "8px",
              verticalAlign: "top",
              float: "right",
              // height: tableHeight,
              // overflowY: "scroll"
            }}
          >
            <ReportChart
              style={{ marginBottom: "8px" }}
              data={this.getChartData()}
              height={tableHeight}
              onChangeReportView={this.onChangeReportView}
              selectedTheme={selectedTheme}
            />
          </div>
        )}
      </div>
    );
  }

  //endregion
}

Summary.propTypes = {
  onLogAction: PropTypes.func.isRequired,
  onUpdateSettings: PropTypes.func.isRequired,
  settings: PropTypes.object,
  waegAPI: PropTypes.func.isRequired,
};

export default Summary;
