import React, { Component } from "react";
import PropTypes from "prop-types";

import { Timer } from "../timer";
import moment from "moment/moment";

class TimerNem extends Component {
  state = {
    style: this.props.style,

    nem_datetime: ""
  };

  componentDidMount() {
    this.startNemCurrentTimer();
  }

  componentWillUnmount() {
    this.stopNemCurrentTimer();
  }

  startNemCurrentTimer = () => {
    this.fetchCurrentNemTime();
    this.nem_timer = setInterval(() => this.fetchCurrentNemTime(), 1000);
  };

  stopNemCurrentTimer = () => {
    clearInterval(this.nem_timer);
  };

  formatUtcDt = rawDt => {
    if (rawDt)
      return moment(rawDt)
        .utcOffset("+1000")
        .format("HH:mm");
    return rawDt;
  };

  fetchCurrentNemTime = () => {
    const now = moment();
    const nemTimeStr = this.formatUtcDt(now);
    if (this.state.nem_datetime !== nemTimeStr) {
      //only update every minute

      this.setState({
        nem_datetime: nemTimeStr
      });
      //send heart beat to logs
      this.props.onLogAction("TimerNem", "update", {
        nemTimeStr: nemTimeStr,
        nemTimeUtc: now,
        dispatchIntervalUtc: this.props.dispatchInterval,
        dispatchIntervalStr: this.formatUtcDt(this.props.dispatchInterval),
        runDateTimeUtc: this.props.runDateTime,
        runDateTimeStr: this.formatUtcDt(this.props.runDateTime)
      });
    }
  };

  render() {
    const { nem_datetime, style } = this.state;
    const { selectedTheme } = this.props;
    return (
      <Timer
        style={style}
        datetimestr={nem_datetime}
        label={"CURRENT NEM TIME"}
        tooltip={"Current NEM time"}
        selectedTheme={selectedTheme}
      />
    );
  }
}

TimerNem.propTypes = {
  style: PropTypes.object,
  dispatchInterval: PropTypes.string,
  runDateTime: PropTypes.string,
  onLogAction: PropTypes.func.isRequired,
  selectedTheme: PropTypes.string.isRequired
};

export default TimerNem;
