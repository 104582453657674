import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

const ButtonGroupMultipleSelection = ({
  options,
  selectedValues,
  onChange,
  style
}) => {
  const isSelected = value => {
    return selectedValues && selectedValues.indexOf(value) >= 0;
  };

  const onToggleButton = value => () => {
    //toggle value on/off selected values list
    const idx = selectedValues.indexOf(value);

    if (idx >= 0)
      //if item already in array of selectedValues
      selectedValues.splice(idx, 1);
    else
      //remove item
      selectedValues.push(value); //add item to array

    //update parent
    onChange(selectedValues);
  };

  return (
    <div style={style}>
      <Button.Group>
        {options.map(o => (
          <Button
            key={o.value}
            type={isSelected(o.value) ? "primary" : "default"}
            onClick={onToggleButton(o.value)}
            size={"large"}
            style={{ height: "48px", lineHeight: "22px" }}
          >
            <b>{o.title}</b>
            {o.sub && <small style={{ display: "block" }}>{o.sub}</small>}
          </Button>
        ))}
      </Button.Group>
    </div>
  );
};

ButtonGroupMultipleSelection.propTypes = {
  style: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      sub: PropTypes.node
    })
  ).isRequired,
  selectedValues: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ButtonGroupMultipleSelection;
