import React from "react";
import PropTypes from "prop-types";
const Footer = ({ style }) => {
  return (
    <footer style={{ textAlign: "center", ...style }}>
      <small>
        WAEG | © 2018{" "}
        <a
          href="http://pdview.com.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          pdView
        </a>{" "}
        Pty Ltd. All rights reserved.{" "}
      </small>
    </footer>
  );
};

Footer.propTypes = {
  style: PropTypes.object
};

export default Footer;
