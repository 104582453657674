import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

import { IconLight } from "../icon_light";

const InfoTooltip = ({ content, style, placement }) => {
  return (
    <span style={{ cursor: "pointer", ...style }}>
      <Tooltip placement={placement} title={content}>
        <span>
          <IconLight icon={"info-circle"} style={{ color: "#999" }} />
        </span>
      </Tooltip>
    </span>
  );
};

InfoTooltip.propTypes = {
  style: PropTypes.object,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placement: PropTypes.string
};

export default InfoTooltip;
