import React from "react";
import PropTypes from "prop-types";

import { Button } from "antd";
import { IconLight } from "../icon_light";

const ReportDownloadCsv = ({ style, onClick, isLoading, hasError }) => {
  return (
    <Button
      loading={isLoading}
      onClick={onClick}
      style={{
        ...style
      }}
      title={`${
        hasError
          ? "Failed to download. Try again."
          : "Download CSV for all groups from selected regions and selected days"
      }`}
    >
      <IconLight icon={hasError ? "exclamation-triangle" : "download"} />
    </Button>
  );
};

ReportDownloadCsv.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool
};

export default ReportDownloadCsv;
