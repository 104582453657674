import React from "react";
import PropTypes from "prop-types";

import { ButtonBorderless, TableGroupActions } from "../../../../_components";

const CellShowAll = ({ style, regionId, onClick, linkColor }) => {
  const onClickShowAll = regionId => () => {
    onClick(TableGroupActions.ShowAll, regionId);
  };

  return (
    <span style={style} onClick={onClickShowAll(regionId)}>
      <ButtonBorderless>
        <span
          style={{
            fontSize: "0.8em",
            textTransform: "uppercase",
            color: linkColor
          }}
        >
          show all
        </span>
      </ButtonBorderless>
    </span>
  );
};

CellShowAll.propTypes = {
  style: PropTypes.object,
  regionId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  linkColor: PropTypes.string.isRequired
};

export default CellShowAll;
