import React from "react";
import PropTypes from "prop-types";
import {
  IconLight,
  ReportVisibilityTypes,
  ButtonBorderless,
  TableGroupActions
} from "../../../index";

const CellVisibilityGroup = ({
  style,
  selectedReportVisibility,
  onClick,
  groupId,
  regionId,
  linkColor
}) => {
  const onClickAction = action => () => {
    onClick(action, regionId, groupId);
  };

  return (
    <span
      style={style}
      title={
        selectedReportVisibility === ReportVisibilityTypes.Visible
          ? "hide row"
          : "show row"
      }
      onClick={onClickAction(
        selectedReportVisibility === ReportVisibilityTypes.Visible
          ? TableGroupActions.Hide
          : TableGroupActions.Show
      )}
    >
      <ButtonBorderless>
        <IconLight
          icon={
            selectedReportVisibility === ReportVisibilityTypes.Visible
              ? "eye-slash"
              : "eye"
          }
          style={{ color: linkColor }}
        />
      </ButtonBorderless>
    </span>
  );
};

CellVisibilityGroup.propTypes = {
  style: PropTypes.object,
  selectedReportVisibility: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  regionId: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired
};

export default CellVisibilityGroup;
