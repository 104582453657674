import React from "react";
import PropTypes from "prop-types";

import { ButtonGroupMultipleSelection } from "../button_group_multiple_selection";
import { ReportViewOptionsTypes } from "./ReportViewOptionsTypes";
import { IconLight } from "../../_components";

const ReportViewOptionsPicker = ({ style, selectedValues, onChange }) => {
  let options = [];
  options.push({
    title: <IconLight icon={"columns"} />,
    value: ReportViewOptionsTypes.Summary,
    sub: "Summary"
  });

  options.push({
    title: <IconLight icon={"chart-area"} />,
    value: ReportViewOptionsTypes.Sparkline,
    sub: "Sparkline"
  });

  return (
    <ButtonGroupMultipleSelection
      style={style}
      options={options}
      selectedValues={selectedValues}
      onChange={onChange}
    />
  );
};

ReportViewOptionsPicker.propTypes = {
  style: PropTypes.object,
  selectedValues: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ReportViewOptionsPicker;
