import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";

const Unauthorised = () => {
  return (
    <div style={{ maxWidth: "300px", margin: "auto", paddingTop: "16px" }}>
      <Alert
        showIcon
        type={"warning"}
        message={"Access denied"}
        description={"You do not have subscription to access this page"}
      />
    </div>
  );
};

Unauthorised.propTypes = {
  message: PropTypes.string
};

export default Unauthorised;
