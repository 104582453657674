import React, { Component } from "react";
import PropTypes from "prop-types";
import { Spinner } from "../_components";

class Logout extends Component {
  constructor() {
    super();
    this.state = {
      loggedOut: false
    };
  }
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  componentWillMount() {
    this.props.auth.logout();
    this.setState({
      loggedOut: true
    });
    //close live chat
    //window.Intercom('shutdown');
  }

  render() {
    const { loggedOut } = this.state;

    if (loggedOut) {
      return {
        /*<Redirect to="/" />*/
      };
    }

    return <Spinner />;
  }
}

export default Logout;
