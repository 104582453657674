import React from "react";
import PropTypes from "prop-types";

import { InfoTooltip } from "../../../../_components";

const CellRegionRowName = ({ style, name, tooltip }) => {
  return (
    <span style={style}>
      {tooltip && (
        <InfoTooltip style={{ marginRight: "4px" }} content={tooltip} />
      )}{" "}
      {name.toUpperCase()}
    </span>
  );
};

CellRegionRowName.propTypes = {
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string
};

export default CellRegionRowName;
