import _ from "lodash";
import moment from "moment";

const MillisecondsOffset = 10 * 60 * 60 * 1000; //utc + 10 hours
const FIFTEEN_MINUTES = 1000 * 60 * 15;
const ONE_DAY = 1000 * 60 * 60 * 24;

export class DataFormatting {
  static formatDateTime(dtRaw) {
    return new Date(dtRaw).getTime() + MillisecondsOffset; //return datetime as required by highcharts
  }

  static formatValue(key, valueRaw) {
    switch (key) {
      case "price":
        return this.formatPriceValue(valueRaw);

      case "expected_generation":
        return this.formatGenValue(valueRaw);
      default:
        console.log("No formatting set for type", key);
        return valueRaw;
    }
  }

  static formatPriceValue(priceRaw) {
    if (priceRaw !== undefined) {
      return parseFloat(priceRaw.toFixed(2));
    }
    return priceRaw;
  }

  static formatGenValue(genRaw) {
    if (genRaw !== undefined) {
      return Math.round(genRaw);
    }
    return genRaw;
  }

  static getSeriesColor(isDarkTheme, key, minValue, maxValue) {
    switch (key) {
      case "expected_generation":
        //for gen change series color to gray if min/max gen between -10/10MW
        if (minValue > -10 && maxValue < 10) return "#aaa";
        else return "#7cb5ec";
      case "price":
        return isDarkTheme ? "#eee" : "#636363";
      default:
        console.log("No color formatting set for key:", key);
        return "red";
    }
  }

  static getTooltipFormats(key) {
    switch (key) {
      case "expected_generation":
        return { valueSuffix: "MW" };
      case "price":
        return { valuePrefix: "$" };
      default:
        console.log("No tooltip format specified for key", key);
        return null;
    }
  }

  static getDateTimeSeries(data, dtKey) {
    let dts = [];

    if (data) {
      data.forEach(d => {
        const dt = d[dtKey];
        dts.push(dt);
      });
    }
    return _.uniq(dts);
  }

  static getChartBands(dtSeries, midnightColor, weekendColor) {
    let midnightPlots = [];
    let weekendBands = [];
    if (dtSeries) {
      dtSeries.forEach(dtRaw => {
        const dt = DataFormatting.formatDateTime(dtRaw);
        const mdt = moment(dtRaw).utcOffset("+1000");
        if (mdt.hours() === 0 && mdt.minutes() === 0) {
          /* Offset by 15 minutes (12:15 am)*/
          const time = dt + FIFTEEN_MINUTES;
          midnightPlots.push({
            value: time,
            zIndex: 3,
            width: 1,
            dashStyle: "dash",
            color: midnightColor
            // label: {
            //   text: mdt.format("ddd"),
            //   style: { color: isDarkTheme ? "#888" : "#ccc" }
            // }
          });

          const day = mdt.day();
          if (day === 0 || day === 6) {
            //weekend plots, only checking on midnight
            weekendBands.push({
              id: `weekend-${weekendBands.length}`,
              color: weekendColor,
              from: dt,
              to: dt + ONE_DAY
            });
          }
        }
      });
    }

    return { midnightPlots, weekendBands };
  }
}
