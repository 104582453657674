import React from "react";
import { Link } from "react-router-dom";
import { RollbackOutlined, FrownOutlined } from "@ant-design/icons";
import { Button } from "antd";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", paddingTop: "16px" }}>
     <FrownOutlined style={{ fontSize: "10em", padding: "24px" }} />
      <h1>404 - Page Not Found</h1>
      <Button type="primary">
        <Link to="/">
        <RollbackOutlined /> Return to home
        </Link>
      </Button>
    </div>
  );
};

export default NotFound;
