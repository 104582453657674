import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

const ButtonBorderless = ({ style, children }) => {
  return (
    <Button
      size={"small"}
      style={{
        border: 0,
        background: "transparent",
        color: "#08827e",
        ...style
      }}
    >
      {children}
    </Button>
  );
};

ButtonBorderless.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node
};

export default ButtonBorderless;
