import auth0 from "auth0-js";
import history from "../history";
import auth0Config from "../../config/auth0.json";

export default class Auth {
  auth0 = new auth0.WebAuth({
    ...auth0Config,
    redirectUri: `${window.location.origin}/callback`
  });

  constructor() {
    const pathName = window.location.pathname;
    const pathIncludeList = ["/app/summary"];
    if (pathIncludeList.indexOf(pathName) >= 0) {
      localStorage.setItem("last_path", pathName);
    }
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.signup = this.signup.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getProfile = this.getProfile.bind(this);

    this.scheduleRenewal();
  }

  userProfile;
  tokenRenewalTimeout;

  login() {
    this.auth0.authorize({
      prompt: "none"
    });
  }

  signup() {
    this.auth0.authorize({
      initialScreen: "signUp"
    });
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        const pathName = localStorage.getItem("last_path");
        if (pathName) history.replace(pathName);
        else history.replace("/");
      } else if (err) {
        // history.replace('/home/table');
        console.log("Error while handling authentication", err, this.auth0);
        //history.replace("/");
        //if(['consent_required', 'interaction_required', 'login_required'].indexOf(err.error) >= 0)
        this.auth0.authorize({ ...auth0Config }); //Note prompt is not set in default config, different from fcaspays
        //, otherwise was causing redirect loop as a first time login, will have to test silent auth still works
        // alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);

    // schedule a token renewal
    this.scheduleRenewal();

    // navigate to the home route
    const pathName = localStorage.getItem("last_path");
    if (pathName) history.replace(pathName);
    else history.replace("/");
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");

    clearTimeout(this.tokenRenewalTimeout);

    // navigate to the sso logout
    window.location = `https://pdview.au.auth0.com/v2/logout?returnTo=${
      window.location.origin
    }`;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }

  getAccessToken() {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      throw new Error("No Access Token found");
    }
    return accessToken;
  }

  getProfile(cb) {
    let accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  renewToken() {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(err);
      } else {
        this.setSession(result);
      }
    });
  }

  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    const delay = expiresAt - Date.now();
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
    }
  }
}
