import React, { Component } from "react";
import PropTypes from "prop-types";
import { Spinner } from "../_components";

class SignUp extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.auth.signup();
  }

  render() {
    return <Spinner message={"Redirecting to sign up page"} />;
  }
}

export default SignUp;
