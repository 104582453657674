import React, { Component } from "react";
import PropTypes from "prop-types";
import { Layout, notification, ConfigProvider } from "antd";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fal } from "@fortawesome/pro-light-svg-icons";
import CryptoJS from "crypto-js";
import axios from "axios";
import { Redirect } from "react-router-dom";

import './reset.css';
import "./App.css";


import { Subscription, AppNames } from "./utils/helpers";
import {
  SiderApp,
  Spinner,
  Unauthorised,
  PageAlert,
  LiveChat,
  ThemeTypes
} from "./views/_components";

import { Summary } from "./views";
import Env from './config/env';
// library.add(fal);

const SETTINGS_KEYID = "waeg";

class App extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  state = {
    profile: {
      loading: true,
      error: false,
      errorMsg: "",
      data: null
    },
    account: {
      loading: true,
      error: false,
      errorMsg: "",
      data: null
    },
    subscription: {
      loading: true,
      error: false,
      errorMsg: "",
      data: null
    },
    settings: {
      loading: false,
      error: false,
      errorMsg: "",
      data: null
    },
    appsAPI: {},
    waegAPI: {},
    utilsAPI: {}
  };
  componentWillMount() {
    const { userProfile, getProfile } = this.props.auth;

    //set user profile in state
    if (!userProfile) {
      getProfile((err, profile) => {
        if (err) {
          console.log("Error while getting auth profile", err);
          this.setState(prevState => ({
            profile: {
              ...prevState.profile,
              error: true,
              loading: false,
              errorMsg: err.message
            }
          }));
        } else {
          this.setState(
            prevState => ({
              profile: {
                ...prevState.profile,
                loading: false,
                data: profile
              }
            }),
            () => this.gotEmail(profile.email)
          );
        }
      });
    } else {
      this.setState(
        prevState => ({
          profile: { ...prevState.profile, loading: false, data: userProfile }
        }),
        () => this.gotEmail(userProfile.email)
      );
    }
  }

  componentDidMount() {
    //init save settings last hash
    const { settings: { data } } = this.state;

    const hash = this.createObjectHash(data);

    //first time, so add hash to compare later on
    //console.log("setting last hash");
    this.setState(prevState => ({
      settings: {
        ...prevState.settings,
        lastHash: hash
      }
    }));
  }

  gotEmail = email => {
    //do stuff once email is fetched from auth token endpoint
    //analytics?, user settings?

    // store.dispatch(getUserSettings(email));
    // /* Create a timer to store the users settings on a regular interval  */
    // setInterval(this.saveUserSettings, config.userSettingSaveInterval);

    const idToken = localStorage.getItem("id_token");

    //create appsAPI instance
    const appsAPI = axios.create({
      baseURL: "https://api.apps.pdview.com.au",
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });

    const waegAPI = axios.create({
      baseURL: Env.WaegApiBaseUrl,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });

    const utilsAPI = axios.create({
      baseURL: "https://api.utils.pdview.com.au",
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });

    this.setState({ appsAPI, waegAPI, utilsAPI }, () =>
      setInterval(this.saveSettings, 10000)
    );

    const self = this;
    //check account
    this.setState(
      prevState => ({
        account: {
          ...prevState.account,
          loading: true
        }
      }),
      () =>
        appsAPI
          .get("/useraccount")
          .then(function(response) {
            //update state
            self.setState(prevState => ({
              account: {
                ...prevState.account,
                loading: false,
                data: response.data
              }
            }));
            //fire notificaiton if required
            if (response.data.status === "REQUIRES_VERIFICATION") {
              notification.warning({
                duration: 0,
                message: "Email verification required",
                description: (
                  <div>
                    Please verify your email to maintain your access. Your
                    account will be locked in{" "}
                    {response.data.timeTillVerificationInDays} days.{" "}
                    <a href={"https://apps.pdview.com.au/app/verifyemail"}>
                      Verify now
                    </a>
                  </div>
                )
              });
            } else if (response.data.status === "LOCKED") {
              window.location = `https://apps.pdview.com.au/accountlocked?email=${
                response.data.email
              }&message=Email+verification+required`;
            }
          })
          .catch(err => {
            console.log("Error occurred in fetching user account", err);
            self.setState(prevState => ({
              account: {
                ...prevState.account,
                loading: false,
                error: true,
                errorMsg: err.message
              }
            }));
          })
    );

    //check subscription
    this.setState(
      prevState => ({
        subscription: {
          ...prevState.subscription,
          loading: true
        }
      }),
      () =>
        appsAPI
          .get("/subscription")
          .then(response => {
            self.setState(prevState => ({
              subscription: {
                ...prevState.subscription,
                loading: false,
                data: response.data,
                helper: new Subscription(response.data)
              }
            }));
          })
          .catch(err => {
            console.log("Error occurred in fetching subscription", err);
            self.setState(prevState => ({
              subscription: {
                ...prevState.subscription,
                loading: false,
                error: true,
                errorMsg: err.message
              }
            }));
          })
    );

    //get settings
    this.setState(
      prevState => ({
        settings: {
          ...prevState.settings,
          loading: true
        }
      }),
      () =>
        utilsAPI
          .get("/getusersettings", { params: { keyid: SETTINGS_KEYID } })
          .then(response => {
            //console.log("LOADED SETTINGS", response.data);
            self.setState(prevState => ({
              settings: {
                ...prevState.settings,
                loading: false,
                data: response.data
                // lastHash: this.createObjectHash(response.data) //don't do it here as once react loads it in, then order of keys is changed and then hash is different
              }
            }));
          })
          .catch(err => {
            console.log("Error occurred in fetching usersettings", err);
            self.setState(prevState => ({
              settings: {
                ...prevState.settings,
                loading: false,
                error: true,
                errorMsg: err.message
              }
            }));
          })
    );
  };

  createObjectHash = obj => {
    return CryptoJS.SHA256(JSON.stringify(obj)).toString(); //don't JSON.stringify the obj before hash as it will, but anyhow did it as otherwise it does not do deep compare
  };

  //save settings to server, invoked on timer
  //compare hash before calling endpoint so that not same settings
  //are overwritten
  saveSettings = () => {
    const { utilsAPI, settings } = this.state;
    const { data, lastHash } = settings;

    const hash = this.createObjectHash(data);
    if (!lastHash) {
      //first time, so add hash to compare
      console.log(
        "No last hash, calculating one. but this should not happen, as last hash is now set in componentDidMount hook"
      );
      this.setState(prevState => ({
        settings: {
          ...prevState.settings,
          lastHash: hash
        }
      }));
    } else if (hash !== lastHash && data && Object.keys(data).length > 0) {
      //console.log("Updating settings", data, hash, lastHash, settings);
      utilsAPI
        .post("/setusersettings", { keyid: SETTINGS_KEYID, payload: data })
        .then(response => {
          //console.log("Updated settings", response);
          this.setState(prevState => ({
            settings: {
              ...prevState.settings,
              lastHash: hash
            }
          }));
        })
        .catch(err => console.error("Error while updating settings", err));
    } else {
      //console.log("Hash is same", data, hash, lastHash);
    }
  };

  logAction = parent => (component, actionType, payload) => {
    const { utilsAPI } = this.state;

    utilsAPI
      .post("/userlog", {
        logger: "WAEG_ACTIVITY",
        payload: {
          client_date: new Date().getTime(),
          component: `${parent}.${component}`,
          action: actionType,
          ...payload
        }
      })
      //.then(response => //console.log("Logged action", response))
      .catch(err => console.error("Error while logging action", err));
  };

  //update settings object in state
  updateSettings = type => data => {
    this.setState(
      prevState => ({
        settings: {
          ...prevState.settings,
          data: {
            ...prevState.settings.data,
            [type]: data
          }
        }
      })
      //,      () => console.log("APP settings updated", this.state.settings)
    );
  };

  getSettings = type => {
    if (
      this.state &&
      this.state.settings &&
      this.state.settings.data &&
      this.state.settings.data[type]
    )
      return this.state.settings.data[type];
    return null;
  };

  appContent = (view, summaryHasAccess) => {
    switch (view) {
      case "summary":
        if (summaryHasAccess)
          return (
            <Summary
              onUpdateSettings={this.updateSettings("summary")}
              settings={this.getSettings("summary")}
              onLogAction={this.logAction("summary")}
              waegAPI={this.state.waegAPI}
            />
          );
        else return <Unauthorised />;

      default:
        const lastPath = localStorage.getItem("last_path");
        if (lastPath && lastPath.indexOf("/app") >= 0) {
          return <Redirect to={lastPath} />;
        } else if (summaryHasAccess) return <Redirect to="/app/summary" />;
        else return <Redirect to="/" />;
    }
  };

  render() {
    const { profile, account, subscription } = this.state;
    const { history, match } = this.props;
    const view = match.params.view;
    const summaryHasAccess =
      subscription &&
      subscription.helper &&
      subscription.helper.hasAccess(AppNames.Summary);
    const subscriptionStatus =
      subscription &&
      subscription.helper &&
      subscription.helper.getSubscription(AppNames.Summary);

    const appsSubscription =
      subscription && subscription.helper && subscription.helper.apps();
    let isDarkTheme = false;
    const settings = this.getSettings("summary");

    if (settings && settings["selectedTheme"]) {
      isDarkTheme = settings["selectedTheme"] === ThemeTypes.Dark;
    }
    return (
      <ConfigProvider theme={{
        token: {
          colorPrimary: "rgb(19, 168, 158)",
          borderRadius: 4,
          padding: "0 15px",
        },
      }}>
      <div className="App">
        {profile.loading || account.loading || subscription.loading ? (
          <Spinner
            style={{ marginTop: "30%" }}
            message={"Loading user profile"}
          />
        ) : profile.error || account.error || subscription.error ? (
          <PageAlert
            message={"Error"}
            description={`${profile.errorMsg} ${account.errorMsg} ${
              subscription.errorMsg
            }`}
          />
        ) : (
          <Layout style={{ minHeight: "100vh" }}>
            <SiderApp
              location={history.location}
              accountEmail={account.data && account.data.email}
              accountStatus={account.data && account.data.status}
              accountDaysLeft={
                account.data && account.data.timeTillVerificationInDays
              }
              summaryHasAccess={summaryHasAccess}
              appsSubscription={appsSubscription}
              subscriptionStatus={subscriptionStatus}
              isDarkTheme={isDarkTheme}
            />
            <Layout>
              <Layout.Content>
                {this.appContent(view, summaryHasAccess)}
              </Layout.Content>
            </Layout>
          </Layout>
        )}
        {profile &&
          profile.data &&
          profile.data.email && (
            <LiveChat email={profile.data.email} name={profile.data.name} />
          )}
      </div>
      </ConfigProvider>
    );
  }
}

export default App;
