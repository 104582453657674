import React from "react";
import PropTypes from "prop-types";
import Intercom from "react-intercom";

const LiveChat = ({ email, name }) => {
  return (
    <Intercom
      className={"live-chat"}
      appID={"dj3h8cb0"}
      email={email}
      name={name}
    />
  );
};

LiveChat.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string.isRequired
};

export default LiveChat;
