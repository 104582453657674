import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { InfoTooltip, ThemeTypes } from "../../_components";

const fullDtFormat = "ddd DD-MM-YYYY HH:mm";
const Timer = ({
  datetime,
  datetimestr,
  label,
  format,
  tooltip,
  style,
  selectedTheme,
  alert,
  alertColour
}) => {
  const dtFormat = format ? format : fullDtFormat;
  let dtStr = datetimestr;
  if (!datetimestr)
    dtStr = datetime
      ? moment(datetime)
          .utcOffset("+1000")
          .format(dtFormat)
      : "...";
  const dtFullStr = datetime
    ? moment(datetime)
        .utcOffset("+1000")
        .format(fullDtFormat)
    : "";

  const isDarkTheme = selectedTheme === ThemeTypes.Dark;
  return (
    <div
      style={{
        display: "inline-block",
        backgroundColor: alert ? alertColour : (isDarkTheme ? "#444" : "#fff"),
        padding: "8px 16px",
        borderRadius: "4px",
        margin: "0 8px",
        ...style
      }}
    >
      {tooltip && <InfoTooltip content={tooltip} placement={"bottom"} />}
      <label style={{ margin: "0 8px", color: "#999" }}>{label}</label>
      <span
        style={{ fontWeight: "bold", color: (isDarkTheme && !alert) ? "#ddd" : "#222" }}
        title={dtFullStr}
      >
        {dtStr}
      </span>
    </div>
  );
};

Timer.propTypes = {
  style: PropTypes.object,
  datetime: PropTypes.string,
  datetimestr: PropTypes.string,
  label: PropTypes.string.isRequired,
  format: PropTypes.string,
  tooltip: PropTypes.string,
  selectedTheme: PropTypes.string.isRequired,
  alert: PropTypes.bool,
  alertColour: PropTypes.string,
};

export default Timer;
