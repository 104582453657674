import React from "react";
import PropTypes from "prop-types";

const CellRegionName = ({
  regionName,
  style,
  shownGroupsCount,
  totalGroupsCount
}) => {
  return (
    <span style={style}>
      <b>{regionName}</b>{" "}
      <small>
        {shownGroupsCount}/{totalGroupsCount} groups shown below
      </small>
    </span>
  );
};

CellRegionName.propTypes = {
  regionName: PropTypes.string.isRequired,
  shownGroupsCount: PropTypes.number.isRequired,
  totalGroupsCount: PropTypes.number.isRequired,
  style: PropTypes.object
};

export default CellRegionName;
